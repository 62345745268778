<!-- Query Raise Modal -->
<div class="container">
  <div class="jumbotron jumbotron-fluid mt-3">
    <div class="row">
      <div class="col">
        <h3>{{queryId}} ({{fieldName}}<span *ngIf="multiRowName"> -> {{multiRowName}}</span>)
          <i *ngIf="queryPriority == 1" title="Low" class="fa fa-fw fa-arrow-down" [style.color] = "queryLowPriorityIconColor"></i>
          <i *ngIf="queryPriority == 2" title="Medium" class="fa fa-fw fa-arrow-up" [style.color] = "queryMediumPriorityIconColor"></i>
          <i *ngIf="queryPriority == 3" title="High" class="fa fa-fw fa-arrow-up" [style.color] = "queryHighPriorityIconColor"></i>
        </h3>
      </div>
      <div class="col-1 text-end mt-2">
        <button type="button" class="btn-close justify-content-end" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
      </div>
    </div>
    <hr>
  </div>
  <div class="row">
    <div class="col-4">
      <table>
        <tr>
          <td><b>Site Name</b></td>
          <td> : </td>
          <td>{{siteName}}</td>
        </tr>
        <tr>
          <td><b>Subject Id</b></td>
          <td> : </td>
          <td>{{subjectAutoId}}</td>
        </tr>
        <tr>
          <td><b>Query Priority</b></td>
          <td> : </td>
          <td> {{queryPriorityData[queryPriority - 1].name}} </td>
        </tr>
      </table>
    </div>
    <div class="col-4">
      <table>
        <tr>
          <td><b>Visit Name</b></td>
          <td> : </td>
          <td>{{visitName}}</td>
        </tr>
        <tr>
          <td><b>Field Name</b></td>
          <td> : </td>
          <td>{{fieldName}}<span *ngIf="multiRowName"> -> {{multiRowName}}</span></td>
        </tr>
        <tr *ngIf="(roles == 6 && userName == 'admin') || roles != 6">
          <td colspan="3"><a (click)="gotoQuestion()" [routerLinkActive]="['router-link-active']" 
            [routerLink]="['/layout/data-entry']">Go To Question</a></td>
        </tr>
      </table>
    </div>
    <div class="col-4">
      <table>
        <tr>
          <td><b>Form Name</b></td>
          <td> : </td>
          <td>{{formName}}</td>
        </tr>
        <!-- <tr *ngIf="roles == 3 || roles == 4">
          <td><b>Assigned To</b></td>
          <td> : </td>
          <td>{{assignedToName}}</td>
        </tr>
        <tr *ngIf="roles == 2 || roles == 5">
          <td><b>Assigned By</b></td>
          <td> : </td>
          <td>{{assignedByName}}</td>
        </tr> -->
      </table>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col" *ngIf="tableData.length; else noData">
      <table class="table table-hover table-light">
        <thead class="thead-light">
          <tr>
            <th>Query Message</th>
            <th>Query Status</th>
            <th>Assigned By</th>
            <th>Assigned To</th>
            <th>Created Date/Time</th>
            <th *ngIf="!showReplyComponent" [hidden]="hiddenByStatus()">
              <button type="button" style="width: 100%;" *ngIf="!showReplyComponent" 
                class="btn btn-outline-primary" [hidden]="statusData == 3 || isLocked == 1" 
                (click)="replyComponent()">
                <b *ngIf="roles != 2">Requery</b>
                <b *ngIf="roles == 2">Respond</b>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableData; let i = index">
            <td>{{ row.message }}</td>
            <td>{{row.status != null ? queryStatus[row.status-1].name : ''}}</td>
            <td>{{ row.assignedByName }} <span *ngIf="row.assignedByRoleId">({{rolesData[row.assignedByRoleId-1].name}})</span></td>
            <td>{{ row.assignedToName }} <span *ngIf="row.aroleId">({{rolesData[row.aroleId-1].name}})</span></td>
            <td>{{ row.createdDate | date : studyDateFormat }}</td>
            <td></td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
  <ng-template #noData>
    <h5>No Data Available!</h5>
  </ng-template>
  <div class="row" *ngIf="showReplyComponent" [formGroup]="fieldQueryDetail">
    <div class="row" *ngIf="showReplyComponent">
      <div class="col-12">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="message" formControlName="message" autocomplete="off"
          placeholder="Enter Your Reply" [required]="true">
          <label for="message">Enter Your Reply</label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showReplyComponent">
      <div class="col-6">
        <div class="form-floating mb-3">
          <select class="form-select" formControlName="assignedTo" (change)="onAssignedSelected($event.target)">
            <option [ngValue]="null" selected disabled>--Select--</option>
            <option *ngFor="let item of assignedToData" value="{{item.id}},{{item.roleId}}">{{item.userName}} ({{item.roleName}})</option>
          </select>
          <label for="floatingSelect">Assigned To</label>
        </div>
      </div>
      <div class="col-6">
        <div class="form-floating mb-3">
          <select class="form-select" id="studyId" formControlName="status" [required]="true">
            <option [ngValue]="null" selected disabled><b>--Select--</b></option>
            <option *ngFor="let item of queryStatus" [value]="item.id"><b>{{item.name}}</b></option>
          </select>
          <label for="studyId"><b>Select Status</b></label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showReplyComponent">
      <div class="col-12 text-center mt-2">
        <button [hidden]="isLocked == 1" [disabled]="replayDisable" type="button" class="btn btn-outline-primary" (click)="raisedFieldQueryReply()">
          <b *ngIf="roles != 2">
            Reply <span class="spinner-border spinner-border-sm" *ngIf="replyLoading" role="status" aria-hidden="true"></span>
          </b>
          <b *ngIf="roles == 2">
            Respond <span class="spinner-border spinner-border-sm" *ngIf="replyLoading" role="status" aria-hidden="true"></span>
          </b>
        </button>&nbsp;
        <button type="button" class="btn btn-outline-warning" (click)="raisedFieldQueryCancel()">
          <b>
            Cancel
          </b>
        </button>
      </div>
    </div>
  </div>
  <br>
  <div class="modal-footer">
    <button type="button" id="cancel" class="btn btn-outline-warning"
    data-bs-dismiss="modal" (click)="closeModal()"><b>Close</b></button> &nbsp;&nbsp;
    <button [hidden]="status == 3 || isLocked == 1" (click)="resetClose()" [disabled]="disableCloseQuery()" class="btn btn-outline-danger" *ngIf="(statusData == 1 || statusData == 2) && roles == 3 || roles == 4" [autoClose]="'outside'" #p="ngbPopover" [ngbPopover]="popContent" [popoverTitle]="popTitle">
        Close query <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
    </button>
    <button [hidden]="status == 1 || status == 2 || isLocked == 1" [disabled]="disableReOpenQuery()" class="btn btn-outline-primary" *ngIf="statusData == 3 && roles == 3 || roles == 4" [autoClose]="'outside'" #p="ngbPopover" [ngbPopover]="popContent" [popoverTitle]="popTitle">
      Reopen query <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
  </button>
  </div>
  <ng-template #popTitle>Reason</ng-template>
  <ng-template #popContent>
    <form *ngIf="closeQueries" [formGroup]="closeQueries">
      <div class="row">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="reason" placeholder="Enter Reason" formControlName="reason" autocomplete="off"/>
          <label for="reason">Enter Reason</label>
        </div>
      </div>
      <div class="row">
        <div class="form-floating mb-3">
          <select class="form-select" formControlName="assignedTo" (change)="onAssignedToStatusChange($event.target)">
            <option [ngValue]="null" selected disabled>--Select--</option>
            <option *ngFor="let item of assignedToQuery" value="{{item.id}},{{item.roleId}}" >{{item.userName}} ({{item.roleName}})</option>
          </select>
          <label for="floatingSelect">Assigned To</label>
        </div>
      </div>
      <div class="row text center">
        <button [hidden]="status == 3 || isLocked == 1" class="btn btn-outline-secondary" (click)="reOpenQuery(3)">Close Query</button>
        <button [hidden]="status == 1 || status == 2 || isLocked == 1" class="btn btn-outline-secondary" (click)="reOpenQuery(1)">Reopen Query</button>
      </div>
    </form>
  </ng-template>
</div>
