import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CaptchaService } from './captcha.service';
import { CAPTCHA_CONFIG } from 'src/app/utils/captcha-config';
import * as loadashJson from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CAPTCHA_MSG } from 'src/app/constant/responseMessage';
@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnChanges{
  @Input("config") config: any = {};
  @Output() captchaCode = new EventEmitter();
  captch_input:any = null;
  code: any = null;
  resultCode:any = null;
  captchaQuestionText = '';
  constructor(private captchService:CaptchaService,private toastr:ToastrService){}
  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config["font"]["size"] = "40px";
      }
      if (!this.config.font || !this.config.font.family) {
        this.config["font"]["family"] = "Arial";
      }
      if (!this.config.strokeColor) {
        this.config["strokeColor"] = "#f20c6c";
      }
      if (!this.config.length) {
        this.config["length"] = 6;
      }
      if (!this.config.cssClass) {
        this.config["cssClass"] = '';
      }

      if (!this.config.type) {
        this.config["type"] = 1;
      }
      
      if (!this.config.back || !this.config.back.stroke) {
        this.config["back"]["stroke"] = "";
      }
      if (!this.config.back || !this.config.back.solid) {
        this.config["back"]["solid"] = "#f2efd2";
      }
      this.config["back"]["stroke"] = "";
      this.createCaptcha();
    }
  }
  createCaptcha() {
    if(this.config == null || loadashJson.isEqual(this.config,{})){
      this.config = CAPTCHA_CONFIG
    }
    this.code= null;
    this.resultCode= null;
    this.captchaQuestionText = '';
    switch(this.config.type) {
      case 1: // only alpha numaric degits to type  
      let char =
      Math.random()
        .toString(24)
        .substring(2, this.config.length) +
      Math.random()
        .toString(24)
        .substring(2, 4);
      this.code = this.resultCode = char.toUpperCase();
      break;
      case 2: // solve the calculation 
      let num1 = Math.floor(Math.random() * 9);
      let num2 = Math.floor(Math.random() * 9);
      let operators = ['+'];
      let operator = operators[(Math.floor(Math.random() * operators.length))];
      this.code =  num1+operator+num2+'=?';
      this.resultCode = (operator === '+') ? (num1 + num2) : (operator === '-') ? (num1 - num2) : (num1 * num2);
      break;
      case 4:
        const symbols = [
          { key: 'clouds', value: '☁' },
          { key: 'telephones', value: '☎' },
          { key: 'stars', value: '★' },
          { key: 'umbrellas', value: '☂' },
          { key: 'smiles', value: '☺' },
          { key: 'scissors', value: '✂' },
          { key: 'aeroplanes', value: '✈' },
          { key: 'trucks', value: '⛟' },
          { key: 'mountains', value: '⛰' }
        ];
        const resultIndex = this.getRandomIntInclusive(0, symbols.length - 1);
        this.resultCode = {
            value: this.getRandomIntInclusive(1, 3),
            key: symbols[resultIndex].key
        };
        this.code = '';
        for (let i = 0; i < this.resultCode.value; i++) {
            this.code += symbols[resultIndex].value;
        }
        symbols.splice(resultIndex, 1);
        symbols.sort(() => .5 - Math.random());
        for (let i = 0; i < (this.config.length - this.resultCode.value); i++) {
            this.code += symbols[i].value;
        }
        this.code = Array.from(this.code).sort(() => .5 - Math.random());
    }
    

    setTimeout(() => {
      const captcahCanvas:any = document.getElementById('captcahCanvas');
      const canvasPosition = { x: 0, y: 0, width: captcahCanvas.width, height: captcahCanvas.height };
      const ctx = captcahCanvas.getContext('2d');
      ctx.clearRect(0,0,captcahCanvas.width,captcahCanvas.height);
      console.log(this.config);
      
      ctx.fillStyle = this.config.back.solid;
      ctx.fillRect(canvasPosition.x, canvasPosition.y, canvasPosition.width, canvasPosition.height);
      // ctx.font = '10px Arial';
      // ctx.fillStyle = "#000000";
      // ctx.fillText("Powered By BinsSoft", captcahCanvas.width - 95, captcahCanvas.height - 5);
      ctx.beginPath();
      if (this.isDotCaptcha()) {
          ctx.fillStyle = '#000';
          const position = { x: 20, y: 20, width: 30, height: 30, thickness: 1 };
          // tslint:disable-next-line:max-line-length
          ctx.fillRect(position.x - (position.thickness), position.y - (position.thickness), position.width + (position.thickness * 2), position.height + (position.thickness * 2));
          ctx.fillStyle = '#FFF';
          ctx.fillRect(position.x, position.y, position.width, position.height);
          ctx.font = '18px ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText('I\'m not a Robot', (position.x) + position.width + 10, (position.y * 2) - 4);
          ctx.font = 'bold 12px ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText('Click on all dots...', (position.x) + position.width + 10, (position.y * 2) + 12);
          const midBox1 = {
              success: false,
              position: this.generateSmallBox(position, ctx)
          };
          const midBox2 = {
              success: false,
              position: this.generateSmallBox(position, ctx)
          };
          let clickCount = 0;
          captcahCanvas.addEventListener('mousedown', (e:any) => {
              clickCount += 1;
              const clickPosition = this.getMousePosition(captcahCanvas, e);
              this.resultCode = 1;
              if (((clickPosition.x >= midBox1.position.x && clickPosition.x <= midBox1.position.x + midBox1.position.width) &&
                  (clickPosition.y >= midBox1.position.y && clickPosition.y <= midBox1.position.y + midBox1.position.height))) {
                  midBox1.success = true;
              }
              if (((clickPosition.x >= midBox2.position.x && clickPosition.x <= midBox2.position.x + midBox2.position.width) &&
                  (clickPosition.y >= midBox2.position.y && clickPosition.y <= midBox2.position.y + midBox2.position.height))) {
                  midBox2.success = true;
              }
              if (clickCount === 2) {
                  if (midBox1.success && midBox2.success) {
                      this.captch_input = 1;
                      ctx.font = '25px ' + this.config.font.family;
                      ctx.fillStyle = '#158a0d';
                      ctx.fillText('✓', position.x + 5, position.y + position.height - 5);
                  }
                  else { // it is a human
                      this.captch_input = 0;
                      this.createCaptcha();
                  }
                  this.checkCaptcha();
              }
          });
      }
      else if (this.isCountCaptcha()) {
          this.captchaQuestionText = 'How many "' + this.resultCode.key + '" can you see?';
          captcahCanvas.style.letterSpacing = 1 + 'px';
          ctx.font = '12px' + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText(this.captchaQuestionText, 0, canvasPosition.height - 5);
          let lastX = 0;
          ctx.font = '23px' + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          let lastY = 60;
          Array.from(this.code).forEach((c, index) => {
              lastY = this.getRandomIntInclusive((canvasPosition.y + 30), (canvasPosition.y + canvasPosition.height - 20));
              lastX += 32;
              ctx.fillText(c, lastX, lastY);
          });
          // if (this.config.back.stroke) {
          //   ctx.strokeStyle = this.config.back.stroke;
          //   for (let i = 0; i < 150; i++) {
          //     ctx.moveTo(Math.random() * 300, Math.random() * 300);
          //     ctx.lineTo(Math.random() * 300, Math.random() * 300);
          //   }
          //   ctx.stroke();
          // }
          this.resultCode = this.resultCode.value;
      }
      else {
        captcahCanvas.style.letterSpacing = 15 + 'px';
        ctx.font = this.config.font.size + ' ' + this.config.font.family;
        ctx.fillStyle = this.config.font.color;
        // ctx.textBaseline = 'middle';
        let lastX = 0;
        let lastY = 60;
        Array.from(this.code).forEach((c, index) => {
            if (this.config.type != 2) {
                lastY = this.getRandomIntInclusive((canvasPosition.y + 30), (canvasPosition.y + canvasPosition.height - 10));
            }
            lastX += 32;
            ctx.fillText(c, lastX, lastY);
        });
        if (this.config.back.stroke) {
            ctx.strokeStyle = this.config.back.stroke;
            for (let i = 0; i < 150; i++) {
                ctx.moveTo(Math.random() * 300, Math.random() * 300);
                ctx.lineTo(Math.random() * 300, Math.random() * 300);
            }
            ctx.stroke();
        }
        this.captchaQuestionText = this.code.split('').join(' ');
      }
    }, 100);
  }

  isDotCaptcha(){
    return this.config.type == 3;
  };
  isCountCaptcha(){
    return this.config.type == 4;
  };
  generateSmallBox(position:any, ctx:any){
    const midBoxPosition = {
        x: this.getRandomIntInclusive((position.x + 3), (position.x + position.width - 6)),
        y: this.getRandomIntInclusive((position.y + 3), (position.y + position.height - 6)),
        width: 6,
        height: 6,
    };
    ctx.fillRect(midBoxPosition.x, midBoxPosition.y, midBoxPosition.width, midBoxPosition.height);
    return midBoxPosition;
  };
  getRandomIntInclusive(min:any, max:any) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
  };

  playCaptcha() {
    console.log(this.code);
    
    var msg = new SpeechSynthesisUtterance(this.captchaQuestionText);
    msg.pitch = 0.1;
    window.speechSynthesis.speak(msg);
  }

  checkCaptcha() {
    console.log("CAPTCHA INPUT :: ",this.captch_input);
    console.log("RESULT CODE :: ",this.resultCode);
    // return this.resultCode
    
    if (this.captch_input != this.resultCode) {
      this.captchService.setCaptchaStatus(false);
      
    } else  {
      this.captchService.setCaptchaStatus(true);
    }
  }

  getMousePosition(captcahCanvas:any, event:any) {
    const rect = captcahCanvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return {
        x,
        y
    };
  }

  onDataEntered(event:any){
    this.captchService.setCaptchaStatus(null)
    this.captch_input = event.target.value
    console.log(this.resultCode);
    if (this.captch_input != this.resultCode) {
      this.captchService.setCaptchaStatus(false);
      this.toastr.warning(CAPTCHA_MSG)
      this.createCaptcha()
    } else  {
      this.captchService.setCaptchaStatus(true);
    }
  }
}

// import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
// import { CaptchaService } from './captcha.service';
// import { CAPTCHA_CONFIG } from 'src/app/utils/captcha-config';
// import * as loadashJson from 'lodash';
// import { ToastrService } from 'ngx-toastr';
// import { CAPTCHA_MSG } from 'src/app/constant/responseMessage';
// @Component({
//   selector: 'app-captcha',
//   templateUrl: './captcha.component.html',
//   styleUrls: ['./captcha.component.css']
// })
// export class CaptchaComponent implements OnChanges{
//   @Input("config") config: any = {};
//   @Output() captchaCode = new EventEmitter();
//   captch_input:any = null;
//   code: any = null;
//   resultCode:any = null;
//   captchaQuestionText = '';
//   constructor(private captchService:CaptchaService,private toastr:ToastrService){}
//   ngOnChanges() {
//     if (this.config) {
//       if (!this.config.font || !this.config.font.size) {
//         this.config["font"]["size"] = "40px";
//       }
//       if (!this.config.font || !this.config.font.family) {
//         this.config["font"]["family"] = "Arial";
//       }
//       if (!this.config.strokeColor) {
//         this.config["strokeColor"] = "#f20c6c";
//       }
//       if (!this.config.length) {
//         this.config["length"] = 6;
//       }
//       if (!this.config.cssClass) {
//         this.config["cssClass"] = '';
//       }

//       if (!this.config.type) {
//         this.config["type"] = 1;
//       }
      
//       if (!this.config.back || !this.config.back.stroke) {
//         this.config["back"]["stroke"] = "";
//       }
//       if (!this.config.back || !this.config.back.solid) {
//         this.config["back"]["solid"] = "#f2efd2";
//       }

//       this.createCaptcha();
//     }
//   }
//   createCaptcha() {
//     if(this.config == null || loadashJson.isEqual(this.config,{})){
//       this.config = CAPTCHA_CONFIG
//     }
//     this.code= null;
//     this.resultCode= null;
//     this.captchaQuestionText = '';
//     switch(this.config.type) {
//       case 1: // only alpha numaric degits to type
  
//       let char =
//       Math.random()
//         .toString(24)
//         .substring(2, this.config.length) +
//       Math.random()
//         .toString(24)
//         .substring(2, 4);
//       this.code = this.resultCode = char.toUpperCase();
//       break;
//       case 2: // solve the calculation 
//       let num1 = Math.floor(Math.random() * 99);
//       let num2 = Math.floor(Math.random() * 9);
//       let operators = ['+','-'];
//       let operator = operators[(Math.floor(Math.random() * operators.length))];
//       this.code =  num1+operator+num2+'=?';
//       this.resultCode = (operator == '+')? (num1+num2):(num1-num2);
//       break;
//       case 4:
//         const symbols = [
//           { key: 'clouds', value: '☁' },
//           { key: 'telephones', value: '☎' },
//           { key: 'stars', value: '★' },
//           { key: 'umbrellas', value: '☂' },
//           { key: 'smiles', value: '☺' },
//           { key: 'scissors', value: '✂' },
//           { key: 'aeroplanes', value: '✈' },
//           { key: 'trucks', value: '⛟' },
//           { key: 'mountains', value: '⛰' }
//         ];
//         const resultIndex = this.getRandomIntInclusive(0, symbols.length - 1);
//         this.resultCode = {
//             value: this.getRandomIntInclusive(1, 3),
//             key: symbols[resultIndex].key
//         };
//         this.code = '';
//         for (let i = 0; i < this.resultCode.value; i++) {
//             this.code += symbols[resultIndex].value;
//         }
//         symbols.splice(resultIndex, 1);
//         symbols.sort(() => .5 - Math.random());
//         for (let i = 0; i < (this.config.length - this.resultCode.value); i++) {
//             this.code += symbols[i].value;
//         }
//         this.code = Array.from(this.code).sort(() => .5 - Math.random());
//     }
    

//     setTimeout(() => {
//       const captcahCanvas:any = document.getElementById('captcahCanvas');
//       const canvasPosition = { x: 0, y: 0, width: captcahCanvas.width, height: captcahCanvas.height };
//       const ctx = captcahCanvas.getContext('2d');
//       ctx.clearRect(0,0,captcahCanvas.width,captcahCanvas.height);
//       console.log(this.config);
      
//       ctx.fillStyle = this.config.back.solid;
//       ctx.fillRect(canvasPosition.x, canvasPosition.y, canvasPosition.width, canvasPosition.height);
//       // ctx.font = '10px Arial';
//       // ctx.fillStyle = "#000000";
//       // ctx.fillText("Powered By BinsSoft", captcahCanvas.width - 95, captcahCanvas.height - 5);
//       ctx.beginPath();
//       if (this.isDotCaptcha()) {
//           ctx.fillStyle = '#000';
//           const position = { x: 20, y: 20, width: 30, height: 30, thickness: 1 };
//           // tslint:disable-next-line:max-line-length
//           ctx.fillRect(position.x - (position.thickness), position.y - (position.thickness), position.width + (position.thickness * 2), position.height + (position.thickness * 2));
//           ctx.fillStyle = '#FFF';
//           ctx.fillRect(position.x, position.y, position.width, position.height);
//           ctx.font = '18px ' + this.config.font.family;
//           ctx.fillStyle = this.config.font.color;
//           ctx.fillText('I\'m not a Robot', (position.x) + position.width + 10, (position.y * 2) - 4);
//           ctx.font = 'bold 12px ' + this.config.font.family;
//           ctx.fillStyle = this.config.font.color;
//           ctx.fillText('Click on all dots...', (position.x) + position.width + 10, (position.y * 2) + 12);
//           const midBox1 = {
//               success: false,
//               position: this.generateSmallBox(position, ctx)
//           };
//           const midBox2 = {
//               success: false,
//               position: this.generateSmallBox(position, ctx)
//           };
//           let clickCount = 0;
//           captcahCanvas.addEventListener('mousedown', (e:any) => {
//               clickCount += 1;
//               const clickPosition = this.getMousePosition(captcahCanvas, e);
//               this.resultCode = 1;
//               if (((clickPosition.x >= midBox1.position.x && clickPosition.x <= midBox1.position.x + midBox1.position.width) &&
//                   (clickPosition.y >= midBox1.position.y && clickPosition.y <= midBox1.position.y + midBox1.position.height))) {
//                   midBox1.success = true;
//               }
//               if (((clickPosition.x >= midBox2.position.x && clickPosition.x <= midBox2.position.x + midBox2.position.width) &&
//                   (clickPosition.y >= midBox2.position.y && clickPosition.y <= midBox2.position.y + midBox2.position.height))) {
//                   midBox2.success = true;
//               }
//               if (clickCount === 2) {
//                   if (midBox1.success && midBox2.success) {
//                       this.captch_input = 1;
//                       ctx.font = '25px ' + this.config.font.family;
//                       ctx.fillStyle = '#158a0d';
//                       ctx.fillText('✓', position.x + 5, position.y + position.height - 5);
//                   }
//                   else { // it is a human
//                       this.captch_input = 0;
//                       this.createCaptcha();
//                   }
//                   this.checkCaptcha();
//               }
//           });
//       }
//       else if (this.isCountCaptcha()) {
//           this.captchaQuestionText = 'How many "' + this.resultCode.key + '" can you see?';
//           captcahCanvas.style.letterSpacing = 1 + 'px';
//           ctx.font = '12px' + ' ' + this.config.font.family;
//           ctx.fillStyle = this.config.font.color;
//           ctx.fillText(this.captchaQuestionText, 0, canvasPosition.height - 5);
//           let lastX = 0;
//           ctx.font = '23px' + ' ' + this.config.font.family;
//           ctx.fillStyle = this.config.font.color;
//           let lastY = 60;
//           Array.from(this.code).forEach((c, index) => {
//               lastY = this.getRandomIntInclusive((canvasPosition.y + 30), (canvasPosition.y + canvasPosition.height - 20));
//               lastX += 32;
//               ctx.fillText(c, lastX, lastY);
//           });
//           // if (this.config.back.stroke) {
//           //   ctx.strokeStyle = this.config.back.stroke;
//           //   for (let i = 0; i < 150; i++) {
//           //     ctx.moveTo(Math.random() * 300, Math.random() * 300);
//           //     ctx.lineTo(Math.random() * 300, Math.random() * 300);
//           //   }
//           //   ctx.stroke();
//           // }
//           this.resultCode = this.resultCode.value;
//       }
//       else {
//         captcahCanvas.style.letterSpacing = 15 + 'px';
//         ctx.font = this.config.font.size + ' ' + this.config.font.family;
//         ctx.fillStyle = this.config.font.color;
//         // ctx.textBaseline = 'middle';
//         let lastX = 0;
//         let lastY = 60;
//         Array.from(this.code).forEach((c, index) => {
//             if (this.config.type != 2) {
//                 lastY = this.getRandomIntInclusive((canvasPosition.y + 30), (canvasPosition.y + canvasPosition.height - 10));
//             }
//             lastX += 32;
//             ctx.fillText(c, lastX, lastY);
//         });
//         if (this.config.back.stroke) {
//             ctx.strokeStyle = this.config.back.stroke;
//             for (let i = 0; i < 150; i++) {
//                 ctx.moveTo(Math.random() * 300, Math.random() * 300);
//                 ctx.lineTo(Math.random() * 300, Math.random() * 300);
//             }
//             ctx.stroke();
//         }
//         this.captchaQuestionText = this.code.split('').join(' ');
//       }
//     }, 100);
//   }

//   isDotCaptcha(){
//     return this.config.type == 3;
//   };
//   isCountCaptcha(){
//     return this.config.type == 4;
//   };
//   generateSmallBox(position:any, ctx:any){
//     const midBoxPosition = {
//         x: this.getRandomIntInclusive((position.x + 3), (position.x + position.width - 6)),
//         y: this.getRandomIntInclusive((position.y + 3), (position.y + position.height - 6)),
//         width: 6,
//         height: 6,
//     };
//     ctx.fillRect(midBoxPosition.x, midBoxPosition.y, midBoxPosition.width, midBoxPosition.height);
//     return midBoxPosition;
//   };
//   getRandomIntInclusive(min:any, max:any) {
//     min = Math.ceil(min);
//     max = Math.floor(max);
//     return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
//   };

//   playCaptcha() {
//     console.log(this.code);
    
//     var msg = new SpeechSynthesisUtterance(this.captchaQuestionText);
//     msg.pitch = 0.1;
//     window.speechSynthesis.speak(msg);
//   }

//   checkCaptcha() {
//     console.log("CAPTCHA INPUT :: ",this.captch_input);
//     console.log("RESULT CODE :: ",this.resultCode);
//     // return this.resultCode
    
//     if (this.captch_input != this.resultCode) {
//       this.captchService.setCaptchaStatus(false);
      
//     } else  {
//       this.captchService.setCaptchaStatus(true);
//     }
//   }

//   getMousePosition(captcahCanvas:any, event:any) {
//     const rect = captcahCanvas.getBoundingClientRect();
//     const x = event.clientX - rect.left;
//     const y = event.clientY - rect.top;
//     return {
//         x,
//         y
//     };
//   }

//   onDataEntered(event:any){
//     this.captchService.setCaptchaStatus(null)
//     this.captch_input = event.target.value
//     console.log(this.resultCode);
//     if (this.captch_input != this.resultCode) {
//       this.captchService.setCaptchaStatus(false);
//       this.toastr.warning(CAPTCHA_MSG)
//       this.createCaptcha()
//     } else  {
//       this.captchService.setCaptchaStatus(true);
//     }
//   }
// }
