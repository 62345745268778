import { ForgotPasswordModule } from './components/forgot-password/forgot-password.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard/auth.guard';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('src/app/components/login/login.module').then((m) => m.LoginModule)
    },
    {
        path: 'layout',
        loadChildren: () => import('src/app/components/layout/layout.module').then((m) => m.LayoutModule)
    },
    {
        path: 'RolesStudy',
        loadChildren: () => import('src/app/components/roles-and-study/roles-and-study.module').then((m) => m.RolesAndStudyModule)
    },
    {
        path: 'forgotPassword',
        loadChildren: () => import('src/app/components/forgot-password/forgot-password-routing.module').then((m) => m.ForgotPasswordRoutingModule)
    },
    {
        path: '**',
        loadChildren: () => import('src/app/components/login/login.module').then((m) => m.LoginModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, AuthGuardService]
})
export class AppRoutingModule {}
