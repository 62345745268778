<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Change Password</h5>
    <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePassword">
      <div class="row">
        <div class="col-12">
          <div class="form-floating mb-3">
            <input type="password" class="form-control" formControlName="currentPassword" id="currentPassword"
              name="currentPassword" placeholder="Enter Current Password" autocomplete="off" autofocus/>
            <label for="currentPassword">Old Password</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-floating mb-3">

            <input 
              type="password" 
              class="form-control" 
              formControlName="newPassword" 
              id="newPassword" 
              name="newPassword" 
              placeholder="Enter New Password" 
              placement="bottom"
              [class.is-invalid]="changePassword.get('newPassword')?.hasError('passwordStrength') || (changePassword.get('newPassword')?.errors?.required && changePassword.get('newPassword')?.touched)"
            />
            <label for="newPassword">New Password</label>
            <small *ngIf="changePassword.get('newPassword')?.errors?.minlength" class="text-danger">
              {{passwordLengthErrorMsg}}
            </small>

            <small *ngIf="
                changePassword.get('newPassword')?.errors?.required &&
                changePassword.get('newPassword')?.touched
              " class="text-danger">
              {{passwordRequiredMsg}}
            </small>
            <small *ngIf="changePassword.get('newPassword')?.hasError('passwordStrength')" class="text-danger">
              {{errorMsg}}
            </small>
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating mb-3">
            <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword"
            [class.is-invalid]="this.changePassword.get('newPassword')?.value != this.changePassword.get('confirmPassword')?.value && changePassword.get('newPassword')?.touched "
              name="confirmPassword" placeholder="Enter Confirm Password" autocomplete="off"/>
            <label for="confirmPassword">Confirm New Password</label>
            <small *ngIf="this.changePassword.get('newPassword')?.value != this.changePassword.get('confirmPassword')?.value && changePassword.get('newPassword')?.touched" class="text-danger">
              {{passwordNotMatchMsg}}
            </small>
          </div>
        </div>

        <div class="errors" *ngIf="
            (changePassword.get('newPassword')?.invalid ||
              changePassword.get('confirmPassword')?.invalid) &&
            (changePassword.get('newPassword')?.touched ||
              changePassword.get('newPassword')?.dirty ||
              changePassword.get('confirmPassword')?.touched ||
              changePassword.get('confirmPassword')?.dirty)
          "></div>
      </div>
    </form>
    <div class="modal-footer">
      <button type="button" id="cancel" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeModal()">
        <b>Close</b>
      </button>
      &nbsp;&nbsp;
      <button class="btn btn-outline-primary" (click)="changeUserPassword()" [hidden]="isLocked == 1"
        [disabled]="!checkFormValue()">
        <b>Save
          <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span></b>
      </button>
    </div>
  </div>
</div>