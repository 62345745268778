import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL, basicAuth } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient) { }

  login(data: any):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
    return this.http.post(`${baseURL}oauth/token`,data,{headers:headers});
  }

  loginWithRefreshToken(data: any):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
    headers.append('Authorization','Basic '+basicAuth)
    console.log(headers);
    return this.http.post(`${baseURL}oauth/token`,data,{headers:headers});
  }

  logout(data?: any):Observable<any>{
    return this.http.post(`${baseURL}auth/logout`,data);
  }

}
