export const CAPTCHA_CONFIG = {
    type: 2, // 1 or 2 or 3 or 4
    length:6,
    back: {
      stroke:"red",
      solid:"transparent"
    } ,
    font:{
     color:"#000000",
     size:"40px"
    },
 
   };