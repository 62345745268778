import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  constructor(private http:HttpClient) { }

  addSignature(roleId:any,userId:any,sign:any):Observable<any>{
    return this.http.post(`${baseURL}users/${VERSION}/addSignature?roleId=${roleId}&userId=${userId}`,sign);
  }
  getSignature(userId:any,roleId:any):Observable<any>{
    return this.http.get(`${baseURL}users/${VERSION}/getSignature?userId=${userId}&roleId=${roleId}`);
  }
}
