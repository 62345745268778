import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { VERSION, baseURL } from '../constant/constant';
import { cloneArray } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor(private http: HttpClient) { }

  updateFormField(data: any): Observable<any> {
    return this.http.put(`${baseURL}fields/${VERSION}`, data);
  }

  deleteFormField(id: any): Observable<any> {
    return this.http.delete(`${baseURL}fields/${VERSION}?id=${id}`)
  }

  addFormField(data: any): Observable<any> {
    return this.http.post(`${baseURL}fields/${VERSION}`, data)
  }

  getFormDB(): Observable<any> {
    return this.http.get(`${baseURL}fields/${VERSION}/visitFormFieldData`)
  }

  getFieldsList():Observable<any>{
    return this.http.get(`${baseURL}fields/${VERSION}/list`);
  }
  
  reOrderFields(data: any): Observable<any> {
    return this.http.put(`${baseURL}fields/${VERSION}/reorder`, data);
  }

  getFieldsByForm(formId:any): Observable<any> {
    return this.http.get(`${baseURL}fields/form/${formId}`);
  }

  exportField(data:any): Observable<any> {
    return this.http.post(`${baseURL}export/field`,data);
  }
}
