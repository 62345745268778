<div class="container-fluid">
  <div class="jumbotron jumbotron-fluid">
    <p class="heading" *ngIf="roles == 3 || roles == readOnlyRole || routers == '/layout/data-review'">{{title}}</p>
    <p class="heading" *ngIf="roles == 4 || routers == '/layout/data-manager'">{{title}}</p>
    <p class="heading" *ngIf="roles == 5 || routers == '/layout/data-sign'">{{title}}</p>
    <!-- <p class="heading" *ngIf="roles == 7 || routers == '/layout/monitor-lock'">{{title}}</p> -->
    <form [formGroup]="dataReviewForm"> 
      <div class="row">
        <div class="col">
          <div class="form-floating mb-2">
            <ng-multiselect-dropdown class="dropdown-list" placeholder="Site" formControlName="site" 
              [settings]="siteDropDownSettings" [data]="sitesList"
              (onDeSelect)="deSelectSite()" (onDeSelectAll)="deSelectSite()" 
              (onSelect)="onSelectSite()" (onSelectAll)="onSelectSite()">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-2">
            <ng-multiselect-dropdown class="dropdown-list" placeholder="Subject" formControlName="subject"
              (onSelect)="onSelectSubject()" (onSelectAll)="onSelectSubject()"
              (onDeSelect)="deSelectSubject()" (onDeSelectAll)="deSelectSubject()" 
              [settings]="subjectDropDownSettings" [data]="subjectsList">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-2">
            <ng-multiselect-dropdown class="dropdown-list" placeholder="Visit" 
            formControlName="visit" [settings]="visitDropdownSettings" [data]="visitsList"
            (onDeSelect)="deSelectVisit()" (onDeSelectAll)="deSelectVisit()"
            (onSelect)="onSelectVisit()" (onSelectAll)="onSelectVisit()"
            ></ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-2">
            <ng-multiselect-dropdown class="dropdown-list"
            formControlName="form"
            placeholder="Form"
            [settings]="formDropdownSettings"
            [data]="formsList"
            ></ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-2">
            <ng-multiselect-dropdown class="dropdown-list"
            formControlName="logForm"
            placeholder="Log form"
            [settings]="logFormDropdownSetting"
            [data]="logFormList"
            ></ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-2">
            <ng-multiselect-dropdown class="dropdown-list"
            formControlName="status"
            placeholder="Status"
            [settings]="statusDropdownSettings"
            [data]="statusList"
            ></ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col" *ngIf="roles == 3 || roles == 5 || roles == 4 || roles == readOnlyRole">
          <button class="btn btn-outline-primary" (click)="isApplyClicked=true; getFormStatus()">
            <b>
              Search
            </b>
          </button>
        </div>
        <div class="col" *ngIf="roles == 5" [hidden]="isLocked == 1 || roles == readOnlyRole">
          <button class="btn btn-outline-info" (click)="fillData()">
            <b>
              Data-Entry
            </b>
          </button>
        </div>
      </div><br>
      <div class="row" *ngIf="tableData.length; else empty">
        <div class="col-md-6 mt-2" [hidden]="isLocked == 1 || roles == readOnlyRole">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="selectAllCheck" (change)="checkUncheckAll($event)" [checked]="masterSelected">
            <label class="form-check-label"><b>Select All</b></label>
          </div>
        </div>
        <div class="col-md-6" [hidden]="isLocked == 1">
          <div class="review-button-group" *ngIf="roles == 3 || roles == 4">
            <button [disabled]="isChecked || reviewLoading" class="btn btn-outline-success review-btn" (click)="changeStatus(roles,2)">
              <!-- For CRA -->
              <b *ngIf="roles == 3">
                SDV <span class="spinner-border spinner-border-sm" *ngIf="reviewLoading" role="status" aria-hidden="true"></span>
              </b>
              <!-- For DM -->
              <b *ngIf="roles == 4">
                DM Review <span class="spinner-border spinner-border-sm" *ngIf="reviewLoading" role="status" aria-hidden="true"></span>
              </b>
            </button>
            <button [disabled]="isChecked || unReviewLoading" class="btn btn-outline-danger unreview-btn" (click)="changeStatus(roles,0)">
              <!-- For CRA -->
              <b *ngIf="roles == 3">
                Non SDV <span class="spinner-border spinner-border-sm" *ngIf="unReviewLoading" role="status" aria-hidden="true"></span>
              </b>
              <!-- For DM -->
              <b *ngIf="roles == 4">
                DM Unreview <span class="spinner-border spinner-border-sm" *ngIf="unReviewLoading" role="status" aria-hidden="true"></span>
              </b>
            </button>
          </div>
          <!-- For Investigator -->
          <div class="review-button-group" *ngIf="roles == 5">
            <button [disabled]="isChecked" class="btn btn-outline-success review-btn" (click)="signOff(2)">
              <b>
                PI Sign <span class="spinner-border spinner-border-sm" *ngIf="piSignLoading" role="status" aria-hidden="true"></span>
              </b>
            </button>
            <button [disabled]="isChecked" class="btn btn-outline-danger review-btn" (click)="signOff(0)">
              <b>
                PI Unsign <span class="spinner-border spinner-border-sm" *ngIf="piUnsignLoading" role="status" aria-hidden="true"></span>
              </b>
            </button>
          </div>
          <!-- For Monitor -->
          <!-- <div class="review-button-group" *ngIf="roles == 7">
            <button [hidden]="isLocked == 1" [disabled]="isChecked" class="btn btn-outline-success review-btn" (click)="monitorLocked(1)">
              <b>
                Lock <span class="spinner-border spinner-border-sm" *ngIf="monitorLockLoading" role="status" aria-hidden="true"></span>
              </b>
            </button>
            <button [hidden]="isLocked == 1" [disabled]="isChecked" class="btn btn-outline-danger review-btn" (click)="monitorLocked(0)">
              <b>
                Unlock <span class="spinner-border spinner-border-sm" *ngIf="monitorUnLockLoading" role="status" aria-hidden="true"></span>
              </b>
            </button>
          </div> -->
        </div>
      </div>
    </form>
  </div>
  <hr>
  <div *ngIf="tableData.length; else noData">
    <table class="table table-hover table-light">
      <thead class="thead-light">
        <tr>
          <th scope="col" [hidden]="isLocked == 1">#</th>
          <th scope="col">Site Name</th>
          <th scope="col">Subject Id</th>
          <th scope="col">Visit Name</th>
          <th scope="col">Form Name</th>
          <!-- <th *ngIf="roles == 4 || roles == 5">CRA Status</th>
          <th *ngIf="roles == 5">DM Status</th> -->
          <th scope="col">Status <i class="fa fa-fw fa-circle-info" [ngbTooltip]="tooltipStatus"></i></th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableData; let i = index" [ngClass]="{'subjectWithdraw':row.subjectStatus > 3 ? true : false}">
          <!-- <td [hidden]="isLocked == 1" *ngIf="roles == 3"><input type="checkbox" class="form-check-input" [checked]="checkedList[i]" [disabled]="row.cra == 1" (change)="isAllSelected($event,row,i)" /></td>
          <td [hidden]="isLocked == 1" *ngIf="roles == 7"><input type="checkbox" class="form-check-input" [checked]="checkedList[i]" [disabled]="row.cra == 0 || row.cra == 1" (change)="isAllSelected($event,row,i)" /></td>
          <td [hidden]="isLocked == 1" *ngIf="roles == 4"><input type="checkbox" class="form-check-input" [checked]="checkedList[i]" [disabled]="row.ml == 0" (change)="isAllSelected($event,row,i)" /></td>
          <td [hidden]="isLocked == 1" *ngIf="roles == 5"><input type="checkbox" class="form-check-input" [checked]="checkedList[i]" [disabled]="row.dm == 0 || row.dm == 1" (change)="isAllSelected($event,row,i)" /></td> -->
          <td [hidden]="isLocked == 1"><input type="checkbox" class="form-check-input" [checked]="checkedList[i]" (change)="isAllSelected($event,row,i)" [disabled]="row.softLock == 2 || row.crc_status == 0"/></td>
          <td>{{ row.siteName }}</td>
          <td>{{ row.subjectAutoId }}</td>
          <td *ngIf="row.visitId == defaultUuid"></td>
          <td *ngIf="row.visitId != defaultUuid">{{row.visitName}}</td>
          <td>{{ row.formName }}</td>
          <td>
            <i [title]="getStatusDesign(row.crc_status,null,null,null,null).title" class="fa fa-fw fa-paper-plane" [style.color]="getStatusDesign(row.crc_status,null,null,null,null).color"></i> &nbsp;
            <!-- <i *ngIf="roles == 3 || roles == 4 || roles == 5 || roles == 7" [title]="getStatusDesign(null,row.cra_status,null,null,null).title" class="fa fa-fw fa-circle" [style.color]="getStatusDesign(null,row.cra_status,null,null,null).color"></i> &nbsp;
            <i *ngIf="roles == 4 || roles == 5 || roles == 7" [title]="getStatusDesign(null,null,row.ml_status,null,null).title" class="fa fa-fw fa-bullseye" [style.color]="getStatusDesign(null,null,row.ml_status,null,null).color"></i> &nbsp;
            <i *ngIf="roles == 4 || roles == 5" [title]="getStatusDesign(null,null,null,row.dm_status,null).title" class="fa fa-fw fa-square" [style.color]="getStatusDesign(null,null,null,row.dm_status,null).color"></i> &nbsp;
            <i *ngIf="roles == 5" [title]="getStatusDesign(null,null,null,null,row.investigator_status).title" class="fa fa-fw fa-check" [style.color]="getStatusDesign(null,null,null,null,row.investigator_status).color"></i> &nbsp; -->
            <i [title]="getStatusDesign(null,row.cra_status,null,null,null).title" class="fa fa-fw fa-circle" [style.color]="getStatusDesign(null,row.cra_status,null,null,null).color"></i> &nbsp;
            <i [title]="getStatusDesign(null,null,row.dm_status,null,null).title" class="fa fa-fw fa-square" [style.color]="getStatusDesign(null,null,row.dm_status,null,null).color"></i> &nbsp;
            <i [title]="getStatusDesign(null,null,null,row.investigator_status,null).title" class="fa fa-fw fa-check" [style.color]="getStatusDesign(null,null,null,row.investigator_status,null).color"></i> &nbsp;
            <i [title]="getStatusDesign(null,null,null,null,row.softLock).title" [ngClass]="row.softLock == 2 ? 'fa fa-fw fa-lock' : 'fa fa-fw fa-unlock'" [style.color]="getStatusDesign(null,null,null,null,row.softLock).color"></i> &nbsp;
          </td>
          <!-- <td *ngIf="roles == 4 || roles == 5">{{ statusList[row.cra_status] }}</td>
          <td *ngIf="roles == 3">{{ statusList[row.cra_status] }}</td>
          <td *ngIf="roles == 4 || roles == 5">{{ statusList[row.dm_status] }}</td>
          <td *ngIf="roles == 5">{{ statusList[row.investigator_status] }}</td> -->
          <td>
            <button id="fill" class="control-buttons" (click)="fillUpForm(row)" title="View Form">
              <!-- <b *ngIf="roles == 3">Review Form</b> -->
              <span class="previewIcon"></span>
              <!-- <b *ngIf="roles == 5">PI Signature Form</b> -->
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="tableFooterHr"></div>
    <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true"
      [rotate]="true" [ellipses]="false" (pageChange)="onChangePagination()">
      <ng-template ngbPaginationPages let-page let-pages="pages">
        <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
          <div class="mb-3 d-flex flex-nowrap px-2">
            <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
            <input
              #i
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              class="form-control custom-pages-input"
              id="paginationInput"
              [value]="page"
              (keyup.enter)="selectPage(i.value, pages.length)"
              (blur)="selectPage(i.value, pages.length)"
              (input)="formatInput($any($event).target, pages.length)"
              aria-labelledby="paginationInputLabel paginationDescription"
              style="width: 2.5rem"
            />
            <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
          </div>
        </li>
      </ng-template>
    </ngb-pagination>
  </div>
  <ng-template #noData>
    <h5>No Data Available!</h5>
  </ng-template>
  <ng-template #tooltipStatus>
    <table>
      <tr align="left">
        <td><i title="Submit for review" class="fa fa-fw fa-paper-plane" [style.color]="successIconColor"></i><b> Submit for review</b></td>
      </tr>
      <tr align="left">
        <td><i title="CRA Status" class="fa fa-fw fa-circle" [style.color]="successIconColor"></i><b> CRA status</b></td>
      </tr>
      <!-- <tr align="left">
        <td><i title="Monitor Lock" class="fa fa-fw fa-bullseye" [style.color]="successIconColor"></i><b> Monitor lock</b></td>
      </tr> -->
      <tr align="left">
        <td><i title="DM Status" class="fa fa-fw fa-square" [style.color]="successIconColor"></i><b> DM status</b></td>
      </tr>
      <tr align="left">
        <td><i title="Investigator Status" class="fa fa-fw fa-check" [style.color]="successIconColor"></i><b> Investigator status</b></td>
      </tr>
    </table>
  </ng-template>
  <ng-template #empty></ng-template>
</div>
