<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">
			Login Credentials
		</h3>
		<button type="button" class="btn-close" (click)="closeModal()"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="loginCredForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="username" id="username" autocomplete="off"
                        name="username" placeholder="Enter UserName" autocomplete="off" maxlength="255"/>
                        <label for="username">UserName</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" formControlName="password" id="password" autocomplete="off"
                        name="password" placeholder="Enter Password" autocomplete="off" maxlength="255"/>
                        <label for="password">Password</label>
                    </div>
                </div>
            </div>
        </form>
	</div>
	<div class="modal-footer">
        <button type="submit" class="btn btn-outline-success" (click)="loginProcess()">
            <b>
                Submit <span class="spinner-border spinner-border-sm" *ngIf="loginLoading" role="status" aria-hidden="true"></span>
            </b>
        </button>
        <button type="button" class="btn btn-outline-danger" (click)="closeModal()">
			<b>Cancel</b>
		</button>
	</div>
</div>