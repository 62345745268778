<!-- Impact Key -->
<div *ngIf="validationAndLogMapping" [formGroup]="validationAndLogMapping">
    <div formArrayName="rule">
        <div class="row" [formGroupName]="ruleLength">
            <!-- Impact Design -->
            <div class="row">
                <div class="col-2 mt-3">
                    <h5>Then Goto</h5>
                </div>
                <!-- Impact Key Started -->
                <div formArrayName="impact">
                    <div *ngFor="let impact of getConditionOrImpactArrayRule('impact',ruleLength).controls;let j=index">
                        <div [formGroupName]="j">
                            <input type="hidden" formControlName="visit" [value]="defaultUuid">
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="formSelect" formControlName="form">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of logVisitListData" [value]="item.formId"><b>{{item.formName}}</b></option>
                                        </select>
                                        <label for="formSelect"><b>Log Form</b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>