import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, REPORTS_NAME } from 'src/app/constant/constant';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Role } from 'src/app/models/Role';
import { AuditlogService } from 'src/app/services/auditlog.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { StudyService } from 'src/app/services/study.service';
import { UserService } from 'src/app/services/user.service';
import { distinctUntilChanged } from 'rxjs';
import * as moment from 'moment';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-reportlog',
  templateUrl: './reportlog.component.html',
  styleUrls: ['./reportlog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportlogComponent implements OnInit {

  
  //userList multi-select component
  usersList: any = [];
  userDropdownSettings: any = {};
  selectedUserIds: any[] = [];
  
  //subject multi-select component
  subjectsList: any = [];
  subjectDropdownSettings: any = {};
  selectedSubjectIds: any[] = [];

  //For Date & Time Filter
  currentDate:any = new Date()
  selectedToDate:string = '';
  selectedFromDate:string = '';

  // For message Filter Search
  selectedMessage:any;

  //insert API data variable
  tableData:any[] = []

  //Pagination
  pageNo:number = DEFAULT_PAGE_NO
  pageSize:number = DEFAULT_PAGE_SIZE
  page:number = 1;
  totalPages:any
  studyId: any;
  roles: any;
  userName: any;
  studyIdData: any;
  studyName: any;
  studyDateFormat: any;
  isDownloadLinkEnable: any;
  reportId: any;
  timerArray: any[] = [];
  auditLogFilter: any;
  studyData: any;


  constructor(private auditlogAPI:AuditlogService, private reportAPI:ReportsService, private toastr:ToastrService,
    private studyAPI:StudyService,private userAPI:UserService,private subjectService:SubjectService) {}

  ngOnInit(): void {
    // Initialize auditLogFilter
    this.auditLogFilter = new FormGroup({
      userIds:new FormControl([]),
      subjectIds:new FormControl([]),
      currentDate:new FormControl([]),
      toDate:new FormControl(),
      fromDate:new FormControl(),
      message:new FormControl("")
    });
    this.multiSelectDropDownSetting();
    this.getStorageData();
    if(this.roles != Role.admin && this.userName != "admin"){
      this.getUserData();
      this.getAllSubject();
    }
    this.onChangePagination();
    if(this.roles == Role.admin && this.userName == "admin"){
      this.getAllStudyData();
    }
    if(this.studyIdData != undefined || this.studyIdData != null){
      this.onStudyChange({value:this.studyIdData});
    }
  }

  //Setup SuperAdmin On Study Change
  getAllStudyData(){
    this.studyAPI.getStudyIdNameList().subscribe(res=>{
      this.studyData = res.responseObject
    })
  }

  onStudyChange(event:any){
    console.log(event.value);
    this.studyId = event.value;
    localStorage.setItem("tempStudyId",event.value);
    if(this.studyId){
      if(this.roles != Role.admin && this.userName != "admin"){
        this.studyName = this.studyData.find((item:any) => item.id == event.value).name;
      }
      this.getUserData();
      this.getAllSubject();
      this.getReportAuditLog()
    }
    this.auditLogFilter.reset()
    this.populateFilterTableData();
  }
  
  auditlogRolewise(){
    if(this.roles == Role.CRC || this.roles == Role.Investigator){
      return false
    }else{
      return true
    }
  }

  selectPage(page: string, pageLength: number) {
    this.page = parseInt(page, 10) || 1;
    this.pageNo = this.page - 1

    if(pageLength<this.pageNo){
      this.pageNo = pageLength;
    }

    if(this.roles == Role.admin && this.userName == "admin"){
      this.studyId = this.studyIdData;
    }
    if(this.studyId){
      this.getReportAuditLog();
    }
	}

  getReportData(timerId:any,reportName:any){
    if(this.studyId){
      this.reportAPI.getReportHistory(reportName).subscribe((res:any) => {
        if(res.responseObject != undefined || res.responseObject != null){
          if(!res.responseObject.inProgress){
            clearInterval(timerId)
            console.log(res,res.responseObject.reportId);
            if(res.responseObject.reportId){
              this.isDownloadLinkEnable = false
              this.reportId = res.responseObject.reportId
            }else{
              this.isDownloadLinkEnable = true
            }
          }
        }
      },err => {
        if(this.timerArray.length > 0){
          for (let index = 0; index < this.timerArray.length; index++) {
            clearInterval(this.timerArray[index])
          }
        }
      })
    }
  }

	formatInput(input: HTMLInputElement, pageLength: number) {
    const FILTER_PAG_REGEX = /[^0-9]/g;
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
    if (pageLength <= Number(input.value)) {
      input.value = String(pageLength);
    }
    (pageLength <= +input.value) ? input.value: pageLength
	}

  //For Storage Data
  getStorageData(){
    this.roles = sessionStorage.getItem("role")
    this.studyId = sessionStorage.getItem("studyId")
    this.userName = sessionStorage.getItem("userName")
    this.studyName = sessionStorage.getItem("studyName")
    this.studyIdData = localStorage.getItem("tempStudyId");
    this.studyDateFormat = sessionStorage.getItem("studyDateFormat")
    if(this.studyDateFormat == null){
      this.studyDateFormat = "dd/MM/y hh:mm:ss a"
    }
  }

  //Setup for multi-select component
  multiSelectDropDownSetting() {

    this.userDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'userName',
      selectAllText: 'Select all',
      unSelectAllText: 'Deselect all',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.subjectDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'subjectId',
      selectAllText: 'Select all',
      unSelectAllText: 'Deselect all',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

  }

  //For Pagination
  onChangePagination(){
    this.pageNo = this.page - 1
    if(this.roles == Role.admin && this.userName == "admin"){
      this.studyId = this.studyIdData;
    }
    this.getReportAuditLog();
  }

  getReportAuditLog() {
    this.tableData = [];
    this.validateTableData()
    this.auditlogAPI.getReportAuditLog(this.selectedSubjectIds,this.selectedUserIds,this.selectedToDate,this.selectedFromDate,this.selectedMessage,this.pageNo,this.pageSize,6).subscribe(res=>{
      console.log("RESPONSE :: ",res);
      this.tableData = res.responseObject.responseDTOS
      let totalCount:number = Number(res.responseObject.totalCount)
      this.totalPages = totalCount
    
      console.log("TOTAL PAGES :: ",this.totalPages);
      
    })
  }

  //API Integration
  getUserData(){
    this.userAPI.getUserIdAndUserName().subscribe(res=>{
      this.usersList = res.responseObject
    })
  }

  filterReportLog(){
    this.populateFilterTableData();
    if(this.studyId){
      this.getReportAuditLog();
    }
  }

  populateFilterTableData(){
    this.pageNo = DEFAULT_PAGE_NO
    this.page = 1
    this.tableData = []
    this.validateTableData();
    if(this.studyIdData != null && this.roles == Role.admin && this.userName == "admin"){
      this.studyId = this.studyIdData;
    }
  }

  validateTableData(){
    const { subjectIds, userIds, toDate, fromDate, message } = this.auditLogFilter.value;
    if(subjectIds && subjectIds.length) {
      this.selectedSubjectIds = subjectIds.map((item:any) => item.id);
    }else {
      this.selectedSubjectIds = [];
    }
    if (userIds && userIds.length) {
      this.selectedUserIds = userIds.map((item: any) => item.id);
    }else{
      this.selectedUserIds = [];
    }
    let toDateFormatted:string = "";
    let fromDateFormatted:string = "";
    if(toDate != null){
      toDateFormatted = JSON.stringify(toDate).replace("T", " ").replace("Z","").replace("\"","").replace("\"","")
      this.selectedToDate = toDate
    }else{
      this.selectedToDate = ""
    }
    
    if (fromDate != null){
      fromDateFormatted = JSON.stringify(fromDate).replace("T", " ").replace("Z","").replace("\"","").replace("\"","")
      this.selectedFromDate = fromDate
    }else{
      this.selectedFromDate = ""
    }
    
    if (message != "" && message != null ){
      this.selectedMessage = message
    }else{
      this.selectedMessage = ""
    }
  }

  //For Date & Time Filter
  onSelectDate(event:any){
    let fromDate:any
    let toDate:any
    if(event.value){
      fromDate = moment(event.value[0]).format("yyyy-MM-DD hh:mm:ss.SSS")
      toDate = moment(event.value[1]).format("yyyy-MM-DD hh:mm:ss.SSS")
      
    }

    if(toDate != null){
      this.auditLogFilter.get("toDate")?.setValue(toDate)
    }
    
    if(fromDate != null){
      this.auditLogFilter.get("fromDate")?.setValue(fromDate)
    }
  }
  
  getAllSubject(){
    this.subjectService.getSubjectIdList().subscribe(res=>{
      console.log("RESPONSE :: ",res);
      this.subjectsList = res.responseObject;
    })
  }

  clearDateFilter(){
    this.auditLogFilter.get('toDate')?.reset();
    this.auditLogFilter.get('fromDate')?.reset();
    this.auditLogFilter.get('currentDate')?.reset();
    this.populateFilterTableData();
    if(this.studyId){
      this.getReportAuditLog();
    }
  }

  clearFilters(){
    this.auditLogFilter.reset()
    this.populateFilterTableData();
    if(this.studyId){
      this.getReportAuditLog();
    }
  }
  
}
