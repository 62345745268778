import { Injectable } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { MONTH_DATA } from "./constant";

@Injectable({
	providedIn: 'root',
  })
export class MaskController {
	mask: string = "yyyy/MM/dd"
	public setMask(mask:any) {
		this.mask = mask;
	}
}

@Injectable()
export class CustomDateParserFormatter {
	constructor(private maskController: MaskController) { }
	get mask() {
		return this.maskController.mask;
	}
	readonly DELIMITER = '-';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				year: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				day: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		let formatter = this.mask
		let dateData:string = ""
		if (!(date?.day && date?.month && date?.year)) return '';
		if(formatter == "DD/MMM/YYYY"){
			dateData = date ? date.day + "/" + `${date?.month.toString() == 'UNK' ? 'UNK' : MONTH_DATA[date.month-1]}` + "/" + date.year : '';
		}else if(formatter == "DD/MM/YYYY"){
			dateData = date ? date.day + "/" + date.month + "/" + date.year : '';
		}else if(formatter == "MM/DD/YYYY"){
			dateData = date ? date.month + "/" + date.day + "/" + date.year : '';
		}else if(formatter == "YYYY/MM/DD"){
			dateData = date ? date.year + "/" + date.month + "/" + date.day : '';
		}else if(formatter == "DD/MM/YY"){
			dateData = date ? date.day + "/" + date.month + "/" + date.year.toString().slice(2,4) : '';
		}else if(formatter == "MM/DD/YY"){
			dateData = date ? date.month + "/" + date.day + "/" + date.year.toString().slice(2,4) : '';
		}else if(formatter == "YYYY/MMM/DD"){
			dateData = date ? date.year + "/" + MONTH_DATA[date.month-1] + "/" + date.day : '';
		}else if(formatter == "YY/MM/DD"){
			dateData = date ? date.year.toString().slice(2,4) + "/" + date.month + "/" + date.day : '';
		}else if(formatter == "DD-MM-YYYY"){
			dateData = date ? date.day + "-" + date.month + "-" + date.year : '';
		}else if(formatter == "DD-MMM-YYYY"){
			dateData = date ? date.day + "-" + MONTH_DATA[date.month-1] + "-" + date.year : '';
		}else if(formatter == "MM-DD-YYYY"){
			dateData = date ? date.month + "-" + date.day + "-" + date.year : '';
		}else if(formatter == "YYYY-MM-DD"){
			dateData = date ? date.year + "-" + date.month + "-" + date.day : '';
		}else if(formatter == "DD-MM-YY"){
			dateData = date ? date.day + "-" + date.month + "-" + date.year.toString().slice(2,4) : '';
		}else if(formatter == "MM-DD-YY"){
			dateData = date ? date.month + "-" + date.day + "-" + date.year.toString().slice(2,4) : '';
		}else if(formatter == "YY-MM-DD"){
			dateData = date ? date.year.toString().slice(2,4) + "-" + date.month + "-" + date.day : '';
		}else if(formatter == "YYYY-MMM-DD"){
			dateData = date ? date.year + "-" + MONTH_DATA[date.month-1] + "-" + date.day : '';
		}else{
			dateData = date ? date.year + "-" + date.month + "-" + date.day : '';
		}
		return dateData
	}
}