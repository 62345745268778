<div class="modal-content">
    <div class="modal-header">
        <h5 *ngIf="showAdd && !isCopyForm" class="modal-title" id="add-form-modal">Add Form</h5>
        <h5 *ngIf="showUpdate && !isCopyForm" class="modal-title" id="add-form-modal">Update Form</h5>
        <h5 *ngIf="isCopyForm" class="modal-title" id="add-form-modal">Copy Form</h5>
        <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <form *ngIf="formValue" [formGroup]="formValue">
            <input type="hidden" name="id" id="id" formControlName="id">
            <div class="mb-3">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="name" formControlName="name" autocomplete="off"
                    placeholder="Enter Form Name" max="500" maxlength="500">
                    <label for="forName">Form Name</label>
                    <i><b>Note:</b> Maximium character allowed 500</i>
                </div>
            </div>
            <div class="mb-3">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="exampleInputAnnotatedName" formControlName="annotatedName" autocomplete="off"
                    placeholder="Enter Annotated Name" maxlength="500" max="500">
                    <label for="forAnnotatedName">Annotated Name</label>
                    <i><b>Note:</b> Maximium character allowed 500</i>
                </div>
            </div>
            <h6>Form Type</h6>
            <div class="d-flex justify-content-between">
                <div class="form-check mb-3" *ngFor="let formType of formTypeData">
                    <input type="radio" [ngClass]="isDisabledRadio() || showUpdate ? 'pe-none form-check-input': 'cursor-pointer form-check-input'"
                    id="exampleInputIsLogForm" formControlName="formType" [value]="formType.value" name="formType">
                    <label class="form-check-label"> {{formType.name}} </label>
                </div>
            </div>
            <div class="" *ngIf="formValue.get('formType')?.value == 0">
                <div class="form-floating mb-1">
                    <ng-multiselect-dropdown formControlName="visitIds" [settings]="dropdownSettings" 
                    [data]="dropdownList" [disabled]="showUpdate == true || isDisabled == true"
                    placeholder="Select Visit"></ng-multiselect-dropdown>
                </div>
            </div>
            <div class="mt-2" *ngIf="isCopyForm">
                <div class="mb-3">
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" 
                        id="exampleInputIsLogForm" formControlName="copyValidation">
                        <label class="form-check-label" for="forIsLogForm"> Copy with Validation </label>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" 
                        id="exampleInputIsLogForm" formControlName="copyFormula">
                        <label class="form-check-label" for="forIsLogForm"> Copy with Formula </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <!-- Buttons -->
        <button type="button" id="cancel" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeModal()">
            <b>
                Cancel
            </b>
        </button>
        <button type="button" id="save" class="btn btn-outline-success" (click)="saveForm()" *ngIf="!isCopyForm && !isButtonDisplay" [hidden]="roles == readOnlyRole" [disabled]="loading">
            <b>
                Save & Exit <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
            </b>
        </button>
        <button type="button" *ngIf="showUpdate && !isCopyForm && !isButtonDisplay" (click)="editFormFields()" class="btn btn-outline-primary" [hidden]="roles == readOnlyRole">
            <b>
                Edit Fields
            </b>
        </button>
        <button type="button" *ngIf="showAdd && !isCopyForm && !isButtonDisplay" (click)="addFormDetails()" class="btn btn-outline-success">
            <b>
                Add Fields
            </b>
        </button>
        <button type="button" *ngIf="isCopyForm" (click)="copyFormDetail()" class="btn btn-outline-success" [disabled]="copyFormLoader">
            <b>
                Copy Form <span class="spinner-border spinner-border-sm" *ngIf="copyFormLoader && !isButtonDisplay" role="status" aria-hidden="true"></span>
            </b>
        </button>
        <button type="button" id="save" class="btn btn-outline-success" *ngIf="showUpdate && isButtonDisplay" (click)="emitAnnotation()" [hidden]="roles == readOnlyRole" [disabled]="loading">
            <b>
                OK <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
            </b>
        </button>
        
        
        
    </div>
</div>