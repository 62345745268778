import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, componentTypeMap } from 'src/app/constant/constant';
import { AuditlogService } from 'src/app/services/auditlog.service';

@Component({
  selector: 'app-note-field-audit-log-popup',
  templateUrl: './note-field-audit-log-popup.component.html',
  styleUrls: ['./note-field-audit-log-popup.component.css']
})
export class NoteFieldAuditLogPopupComponent implements OnInit {

  //Getting Data from modalService
  @Input() subjectId:any
  @Input() visitId:any
  @Input() formId:any
  @Input() fieldId:any
  @Input() fieldName:any
  @Input() fieldDataTypeId:any
  @Input() rowId:any
  @Input() multiRowId:any

  //Store the fieldLevel AuditLog Data
  fieldLevelData:any[] = []
  //Pagination
  pageNo:number = DEFAULT_PAGE_NO
  pageSize:number = DEFAULT_PAGE_SIZE
  page:number = 1;
  totalPages:any

  studyDateFormat:any

  constructor(private auditLogAPI:AuditlogService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.getStorageData()
    this.getFieldLevelHistory()
  }

  getStorageData(){
    this.studyDateFormat = sessionStorage.getItem("studyDateFormat")
  }

  //Field Level AuditLog API
  getFieldLevelHistory(){
    if(this.fieldDataTypeId == 17 || this.fieldDataTypeId == 18){
      this.fieldLevelHistoryForMultiRow()
    }else{
      this.fieldLevelHistory()
    }
  }

  fieldLevelHistory(){
    const DEFAULT_ID:any = -1
    this.auditLogAPI.getFieldLevelAuditlog(this.subjectId,this.visitId,this.formId,
    this.fieldId,DEFAULT_ID,DEFAULT_ID,this.pageNo,this.pageSize).subscribe(res=>{
      console.log("RESPONSE :: ",res);
      this.fieldLevelData = res.responseObject.responseData
      let total:any = Number(res.responseObject.totalCount)
      this.totalPages = total
    })
  }

  fieldLevelHistoryForMultiRow(){
    this.auditLogAPI.getFieldLevelAuditlog(this.subjectId,this.visitId,this.formId,
    this.fieldId,this.rowId,this.multiRowId,this.pageNo,this.pageSize).subscribe(res=>{
      console.log("RESPONSE :: ",res);
      this.fieldLevelData = res.responseObject.responseData
      let total:any = Number(res.responseObject.totalCount)
      this.totalPages = total
    })
  }

  //Close modal
  closeModal(){
    this.modalService.dismissAll()
  }

  //For Adding Note for CRA & DM
  /*insertNote(id:number,note:string){
    
    this.addNoteData.controls['id'].setValue(id)
    if(note == null || note == undefined || note == ""){
      this.addNoteData.controls['note'].setValue("Note: "+note)
    }else{
      this.addNoteData.controls['note'].setValue(note)
    }
  }
  
  addNote(){
    this.addNoteData.controls['note'].setValue(this.addNoteData.get('note')?.value)
    this.addNoteLoading = true
    this.api.addNote(this.addNoteData.value).subscribe(res=>{
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        for (let index = 0; index < this.fields.length; index++) {
          if(this.fields[index].id == this.addNoteData.value.id){
            this.fields[index].note = this.addNoteData.value.note
          }
        }
        this.addNoteData.reset()
        let ref = document.getElementById('close')
        ref?.click()
        this.addNoteLoading = false
      }
    },err=>{
      this.addNoteLoading = false
    })
  }*/

  //Pagination
  onChangePagination(){
    this.pageNo = this.page - 1
    this.fieldLevelHistory()
  }

  selectPage(page: string) {
    this.page = parseInt(page, 10) || 1;
    this.pageNo = this.page - 1
    // this.populateTableData()
	}

	formatInput(input: HTMLInputElement) {
    const FILTER_PAG_REGEX = /[^0-9]/g;
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
	}
}
