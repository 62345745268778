import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class SubjectCommentService {

  constructor(private http:HttpClient) { }

  // saveSubjectComment(subjectId:any,visitId:any,formId:any,fieldId:any,payload:any):Observable<any>{
  //   return this.http.post(`${baseURL}subject/${subjectId}/visits/${visitId}/forms/${formId}/fields/${fieldId}`,payload);
  // }

  updateSubjectComment(subjectId:any,visitId:any,formId:any,fieldId:any,payload:any):Observable<any>{
    return this.http.put(`${baseURL}subject/${subjectId}/visits/${visitId}/forms/${formId}/fields/${fieldId}`,payload);
  }

  resetSubjectComment(subjectId:any,visitId:any,formId:any,fieldId:any,payload:any):Observable<any>{
    return this.http.put(`${baseURL}subject/${subjectId}/visits/${visitId}/forms/${formId}/fields/${fieldId}/reset`,payload);
  }

  getAllSubjectComment(subjectId:any,visitId:any,formId:any): Observable<any>{
    return this.http.get(`${baseURL}subject/${subjectId}/visits/${visitId}/forms/${formId}`);
  }
  
  // deleteSubjectComment(subjectId:any,visitId:any,formId:any,fieldId:any,unitId: string):Observable<any>{
  //   return this.http.delete(`${baseURL}subject/${subjectId}/visits/${visitId}/forms/${formId}/fields/${fieldId}?id=${unitId}`);
  // }

}
