import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PARTIAL_SUCCESS_ICON_COLOR } from 'src/app/constant/color-code';

@Component({
  selector: 'app-warn-if-validation',
  templateUrl: './warn-if-validation.component.html',
  styleUrls: ['./warn-if-validation.component.css']
})
export class WarnIfValidationComponent implements OnInit {

  @Input() visitId:any 
  @Input() visitName:any 
  @Input() formId:any 
  @Input() formName:any 
  @Input() subjectId:any 
  @Input() subjectName:any 
  @Input() fieldId:any 
  @Input() causeFieldId:any 
  @Input() fieldValue:any 
  @Input() message:any
  @Input() fieldName:any
  @Input() multiRowId:any
  @Input() rowId:any

  userId:any
  roles:any

  queryRaised:any = {}

  @Output() passingQueryRaised: EventEmitter<any> = new EventEmitter();
  @Output() passingCloseBtnData: EventEmitter<any> = new EventEmitter();

  partialSuccessIconColor:any = PARTIAL_SUCCESS_ICON_COLOR

  constructor(private modalService:NgbModal,private modalData:NgbActiveModal) {
    this.modalService.activeInstances.subscribe((list:any)=>{
      console.log("MAIN LIST :: ",list);
      
    })
  }

  ngOnInit(): void {
    this.getStorageData()
  }

  getStorageData(){
    this.userId = sessionStorage.getItem("userId")
    this.roles = sessionStorage.getItem('role')
  }

  closePopup() {
    this.queryRaised = {
      fieldId:this.fieldId, 
      fieldName:this.fieldName,  
      multiRowId: this.multiRowId,
      rowId: this.rowId,
      // causeFieldId:this.causeFieldId, 
      fieldValue: this.fieldValue, 
      queryDesc:this.message,
      validationType:6,
      queryPriority:3,
      assignedTo:this.userId,
      aroleId:this.roles,
      assignedBy:this.userId,
      oroleId:this.roles,
      status:1
    }
    this.modalData.close();
    this.passingCloseBtnData.emit(this.queryRaised)
  }

  acceptWarning(){
    this.queryRaised = {
      fieldId:this.fieldId, 
      fieldName:this.fieldName, 
      // causeFieldId:this.causeFieldId, 
      fieldValue: this.fieldValue, 
      multiRowId: this.multiRowId,
      rowId: this.rowId,
      queryDesc:this.message,
      validationType:6,
      queryPriority:3,
      assignedTo:this.userId,
      aroleId:this.roles,
      assignedBy:this.userId,
      oroleId:this.roles,
      status:1
    }
    console.log(this.queryRaised)
    this.passingQueryRaised.emit(this.queryRaised)
    this.modalData.close();
    
  }
}
