import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PasswordStrengthValidator } from 'src/app/constant/password-strength.validators';
import { CONFIRM_PASSWORD_NULL_MSG, CURRENT_PASSWORD_NULL_MSG, NEW_PASSWORD_NULL_MSG, PASSWORD_NOT_MATCH_MSG, USERID_NULL_MSG, PASSWORD_SPECIAL_CHAR_ERROR_MSG, PASSWORD_REQUIRED_MSG, PASSWORD_LENGTH_ERROR_MSG } from 'src/app/constant/responseMessage';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  
  @Input() userIdData: any;
  @Input() fromLogin: any;

  changePassword: FormGroup;
  loading: boolean = false;
  userId: any;
  isLocked: any;
  errorMsg: string = PASSWORD_SPECIAL_CHAR_ERROR_MSG;
  passwordRequiredMsg: string = PASSWORD_REQUIRED_MSG;
  passwordLengthErrorMsg: string = PASSWORD_LENGTH_ERROR_MSG;
  isPopupVisible: boolean = false;
  passwordNotMatchMsg: string = PASSWORD_NOT_MATCH_MSG

  // @Output() btnClicked: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private userService: UserService, private modelService: NgbModal, private toastr: ToastrService, 
    private fb: FormBuilder, private route:Router) {
    // this.changePassword = new FormGroup({
    //   id:new FormControl(),
    //   currentPassword:new FormControl(),
    //   newPassword:new FormControl(),
    //   confirmPassword:new FormControl()
    // })

    this.changePassword = this.fb.group({
      id: [''],
      currentPassword: ['', [Validators.required]],
      newPassword: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordStrengthValidator,
        ]),
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8)
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.localStorageData();
  }

  localStorageData() {
    this.userId = sessionStorage.getItem('userId');
    this.isLocked = sessionStorage.getItem('isLocked');
  }

  closeModal() {
    this.modelService.dismissAll();
    // this.btnClicked.emit(true)
  }

  validateForm() {
    if (
      this.changePassword.value.id == '' ||
      this.changePassword.value.id == null
    ) {
      this.toastr.warning(USERID_NULL_MSG);
      return false;
    } else if (
      this.changePassword.value.currentPassword == '' ||
      this.changePassword.value.currentPassword == null
    ) {
      this.toastr.warning(CURRENT_PASSWORD_NULL_MSG);
      return false;
    } else if (
      this.changePassword.value.newPassword == '' ||
      this.changePassword.value.newPassword == null
    ) {
      this.toastr.warning(NEW_PASSWORD_NULL_MSG);
      return false;
    } else if (
      this.changePassword.value.confirmPassword == '' ||
      this.changePassword.value.confirmPassword == null
    ) {
      this.toastr.warning(CONFIRM_PASSWORD_NULL_MSG);
      return false;
    } else if (
      this.changePassword.get('newPassword')?.value !=
      this.changePassword.get('confirmPassword')?.value
    ) {
      this.toastr.warning(PASSWORD_NOT_MATCH_MSG);
      return false;
    }

    return true;
  }

  changeUserPassword() {
    this.loading = true;
    if(this.userIdData != null){
      this.changePassword.controls['id'].setValue(this.userIdData);
    }else{
      this.changePassword.controls['id'].setValue(this.userId);
    }
    if (this.validateForm()) {
      this.userService
        .changePassword(this.changePassword.value)
        .subscribe((res) => {
          if (res.status != 0) {
            this.toastr.error(res.message);
          } else {
            this.toastr.success(res.message);
            this.modelService.dismissAll();
            this.changePassword.reset();
            if(this.fromLogin && this.fromLogin == true){
              this.route.navigate([''])
            }
            // this.btnClicked.emit(true)
          }

          this.loading = false;
        });
    }
  }

  checkFormValue() {
    if (this.changePassword.get('newPassword')?.hasError('passwordStrength')) {
      this.isPopupVisible = true;
    } else {
      this.isPopupVisible = false;
    }

    if (
      this.changePassword.value.currentPassword &&
      this.changePassword.value.confirmPassword &&
      this.changePassword.value.newPassword &&
      this.changePassword.get('newPassword')?.value == this.changePassword.get('confirmPassword')?.value && 
      !this.changePassword.get('newPassword')?.hasError("passwordStrength")
    ) {
      return true;
    } else {
      return false;
    }
  }
}
