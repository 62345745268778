<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">
			Unschedule Visit <span *ngIf="rowData.subjectId != null">({{rowData.subjectId}})</span>
		</h3>
		<button type="button" class="btn-close" (click)="closeModal()" [disabled]="saveLoading"></button>
	</div>
	<div class="modal-body">
        <form [formGroup]="unScheduleVisitForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="name" id="name" autocomplete="off"
                        name="name" placeholder="Enter Unschedule Visit Name" autocomplete="off" maxlength="255"/>
                        <label for="name">Unschedule Visit Name</label>
                        <i><b>Note:</b> Maximium character allowed 255</i>
                    </div>
                </div>
            </div>
            <!-- TODO : change text -->
            <div class="row">
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <select class="form-select" formControlName="orderId" (change)="onVisitSelected($event.target)">
                          <option [ngValue]="null" selected disabled>--Select--</option>
                          <option *ngFor="let item of visitList" [value]="item.orderId">{{item.name}}</option>
                        </select>
                        <label for="floatingSelect">Select Visit</label>
                      </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-floating mb-2">
                        <ng-multiselect-dropdown class="dropdown-list"
                        formControlName="formIds"
                        placeholder="Form"
                        [settings]="formDropdownSettings"
                        [data]="formsList"
                        (onDeSelect)="onUnSelectForm()"
                        (onDeSelectAll)="onUnSelectForm()"
                        (onSelect)=" onSelectForm()"
                        (onSelectAll)=" onSelectForm()"
                        ></ng-multiselect-dropdown>
                      </div>
                </div>
            </div>
        </form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-danger" (click)="closeModal()" [disabled]="saveLoading">
            <b>
                Close
            </b>
        </button>
        <button type="submit" class="btn btn-outline-success" (click)="saveUnScheduleVisit()" [disabled]="saveLoading">
            <b>
                Save <span class="spinner-border spinner-border-sm" *ngIf="saveLoading" aria-hidden="true"></span>
            </b>
        </button>
	</div>
</div>