export class Validation {
  name:any
  rule:any[] = [
    {
      id: null,
      name: "",
      action:'',
      message: null,
      equation: "",
      condition:[
        {
          id:'',
          visitId:'',
          formId:'',
          fieldId:'',
          operator:'',
          dataTypeId:'',
          fieldValue:'',
          jsonFieldValue:'',
          lOperator:'',
          eqOrderId:'',
        }
      ],
      impact: [
        {
          id:'',
          visit:'',
          form:'',
          fields:[],
        }
      ]
    }
  ]
}
