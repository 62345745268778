import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DEFAULT_UUID, VALIDATION_IMPACT_ACTION } from 'src/app/constant/constant';
import { cloneArray } from 'src/app/constant/globalFunction';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-logmapping-impact',
  templateUrl: './logmapping-impact.component.html',
  styleUrls: ['./logmapping-impact.component.css']
})
export class LogmappingImpactComponent implements OnInit{
  
  @Input() dataTypeId:any;
  @Input() formType:any;
  @Input() fetchedVisitId:any;
  @Input() fetchedFormId:any;
  @Input() fetchedFieldId:any;

  @Input() fetchValidationData:any
  @Input() formRuleValidation!:FormGroup
  @Input() validationRuleId:any;
  @Input() ruleLength:any
  @Input() tabName:any

  validationAndLogMapping!:FormGroup
  validationAction:any = VALIDATION_IMPACT_ACTION

  logVisitListData:any

  defaultUuid:any = DEFAULT_UUID

  constructor(private formAPI:FormService){}

  ngOnInit(): void {
    this.validationAndLogMapping = this.formRuleValidation
    this.getLogFormData()
  }

  //Getting Log Forms from API
  getLogFormData(){
    this.formAPI.getLogFormAPIData().subscribe(res => {
      const forms = res.responseObject['visit'][DEFAULT_UUID].forms
      this.logVisitListData = this.extractForms(forms)
      this.initializeLogMappingImpactData()
    })
  }

  //Extracting Forms Data for setting it to selectionBox
  extractForms(visits: any[]) {
    let formsArray:any[] = []
    Object.keys(visits).map((key:any) => {
      formsArray.push({formId: visits[key].formId, formName: visits[key].formName, formType:visits[key].formType})
    })
    return formsArray
  }

  initializeLogMappingImpactData(){
    if(this.getSelectedRuleKeyValue("id",this.ruleLength) != null){
      for (let validationImpactLogMappingIndex = 0; validationImpactLogMappingIndex < this.fetchValidationData[this.ruleLength].impact.length; validationImpactLogMappingIndex++) {
        this.getConditionOrImpactArrayKey("impact",this.ruleLength,validationImpactLogMappingIndex).reset()
        this.getConditionOrImpactArrayKey("impact",this.ruleLength,validationImpactLogMappingIndex).patchValue(this.fetchValidationData[this.ruleLength].impact[validationImpactLogMappingIndex])
        this.setSelectedKeyValueForConditionAndImpact('impact', 'id', this.ruleLength, validationImpactLogMappingIndex, this.fetchValidationData[this.ruleLength].impact[validationImpactLogMappingIndex].id);
        this.setSelectedKeyValueForConditionAndImpact('impact', 'forms', this.ruleLength, validationImpactLogMappingIndex, this.fetchValidationData[this.ruleLength].impact[validationImpactLogMappingIndex].forms);
        this.setSelectedKeyValueForConditionAndImpact('impact', 'visit', this.ruleLength, validationImpactLogMappingIndex, this.defaultUuid);
      }
    }
  }

  
  //Getting Selected Rule Key Value
  getSelectedRuleKeyValue(keyName:any,i:any){
    return ((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.value
  }

  getConditionOrImpactArrayRule(key:any,i:any){
    return (((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray)
  }

  getConditionOrImpactArrayKey(key:any,i:any,j:any){
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray).get(j.toString()) as FormGroup)
  }

  setSelectedKeyValueForConditionAndImpact(mainKey:any,keyName:any,i:any,j:any,value:any){    
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

}
