<div class="container d-flex justify-content-center">
  <div class="row">
    <div class="jumbotron jumbotron-fluid">
      <div class="col">
        <form *ngIf="formValue" [formGroup]="formValue" novalidate>
          <div class="card mt-5">
            <div class="card-header">
              <div class="display-5">
                Forgot Password
              </div>
            </div>
            <div class="card-body">
              <div>
                <label><b>To resend your password, provide<br> the registered email-id.</b></label>
              </div>
              <br>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="name" formControlName="email" placeholder="Enter Email" autocomplete="off">
                <label for="forName">Email</label>
              </div>
            </div>
            <!-- Submit Button -->
            <div class="card-footer text-center">
              <!-- [routerLink]="['/layout/dashboard']" -->
              <!-- <button type="submit" class="btn btn-outline-primary" (click)="resetPassword()">
                Reset-Password <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
              </button> &nbsp;&nbsp; -->
              <button type="submit" class="btn btn-outline-primary" (click)="resendPassword()">
                <b>
                  Resend Password <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                </b>
              </button> &nbsp;
              <button type="submit" class="btn btn-outline-danger" (click)="onCancelClicked()">
                <b>
                  Cancel
                </b>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
