import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fabric } from "fabric";
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { FORM_REVIEW_NOT_REVIEWED_MSG, INV_REVIEW_SUCCESS_MSG, SIGNATURE_FILE_SIZE_MSG } from 'src/app/constant/responseMessage';
import { ReviewService } from 'src/app/services/review.service';
import { SignatureService } from 'src/app/services/signature.service';
@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.css']
})
export class AddSignatureComponent implements OnInit {
  
  @Input() mainData:any;
  @Input() isFormLevel:any;
  @Input() status:any;
  @Input() isFromReview:any;
  
  
  loading:boolean = false
  reviewLoading:boolean = false
  isVisible:boolean = false
  isSignatureExist?:boolean
  isReviewDataExist?:boolean
  isUnReviewDataExist?:boolean
  unReviewLoading?:boolean
  isUserReset?:boolean;

  userId:any
  roleId:any
  isLocked:any
  signAs:any
  font:any
  
  signSelection:any[] = [{id:1,name:"By Text"},{id:2,name:"By Draw Signature"},{id:3,name:"Upload"}]
  fontSelection:any[] = [{id:1,name:"Arial"},{id:2,name:"Times New Roman"},{id:3,name:"Verdana"},{id:4,name:"Impact"}]
  
  canvas!:fabric.Canvas
  canvasId:any
  getSign:any

  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  constructor(private modalService:NgbModal,private signAPI:SignatureService,private toastr:ToastrService,
    private reviewAPI:ReviewService) {
  }
  
  ngOnInit(): void {
    this.sessionStorageData()
    this.getSignature()
    if(this.mainData){
      if(this.mainData.status == 0){
        this.isUnReviewDataExist = false
        this.isReviewDataExist = false
      }else{
        this.isUnReviewDataExist = false
        this.isReviewDataExist = false
      }
      this.isUserReset = true
    }else{
      this.isUserReset = false
    }
  }

  sessionStorageData(){
    this.userId = sessionStorage.getItem("userId")
    this.roleId = sessionStorage.getItem("role")
    this.isLocked = sessionStorage.getItem("isLocked")
  }

  closeModal(){
    this.modalService.dismissAll()
  }

  onSignSelected(event:any,i:any){
    if(event.target.value == this.signSelection[i].id){
      this.signAs = Number(event.target.value)
      this.isVisible = true
    }
  }
  onFontChange(event:any){
    this.font = event.value
  }

  addSignature(){
    this.reviewLoading = false
    let role = Number.parseInt(this.roleId)
    let element;
    if(this.signAs == 1){
      element = (<HTMLInputElement>document.getElementById('textSign'))
    }else{
      element = this.canvasId
    }
    if(this.signAs != 3){
      html2canvas(element).then(data =>{
        data.toBlob((blob:any)=>{
          let file = new File([blob],"signature.png")
          let formData = new FormData()
          formData.append('id',this.userId)
          formData.append('sign',file)
          this.loading = true
          this.signAPI.addSignature(role,this.userId,formData).subscribe(res=>{
            console.log("RESPONSE :: ",res);
            if(res.status != 0){
              this.toastr.error(res.message)
            }else{
              this.toastr.success(res.message)
              if(this.isFromReview){
                this.getSignature()
              }else{
                this.closeModal()
              }
            }
            this.loading = false
          },err => {
            this.loading = false
          })
        })
      })
    }else{
      if(element){
        let file = new File([element],"signature.png")
        let formData = new FormData()
        formData.append('id',this.userId)
        formData.append('sign',file)
        this.loading = true
        this.signAPI.addSignature(role,this.userId,formData).subscribe(res=>{
          console.log("RESPONSE :: ",res);
          if(res.status != 0){
            this.toastr.error(res.message)
          }else{
            this.toastr.success(res.message)
            if(this.isFromReview){
              this.getSignature()
            }else{
              this.closeModal()
            }
          }
          this.loading = false
        },err => {
          this.loading = false
        })
      }
    }
  }

  startBrush(){
    this.canvasId = (<HTMLCanvasElement>document.getElementById('signCanvas'))
    this.canvas = new fabric.Canvas(this.canvasId)
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas)
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.color = 'black';
    this.canvas.freeDrawingBrush.width = 6;
    this.canvas.renderAll();
  }
  clearBrush(){
    this.canvas.clear();
    this.canvas.renderAll()
  }

  getSignature(){
    this.signAPI.getSignature(this.userId,Number(this.roleId)).subscribe(res=>{
      console.log("RESPONSE :: ",res);
      if(res.responseObject){
        this.isSignatureExist = true
        if(this.mainData){
          if(this.mainData.status == 0){
            this.isUnReviewDataExist = true
            this.isReviewDataExist = false
          }else{
            this.isUnReviewDataExist = false
            this.isReviewDataExist = true
          }
        }
        this.isUserReset = false
        this.getSign = res.responseObject
      }else{
        this.isSignatureExist = false
        this.isUserReset = true
        
      }
    })
  }
  reviewForms(){
    this.reviewLoading = true
    if(this.isFormLevel){
      this.reviewAPI.postFormStatus(this.mainData).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          let response:string = res.responseObject
          if(response.length > 0){
            this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
          }else{
            this.toastr.success(INV_REVIEW_SUCCESS_MSG)
          }
        }
        this.modalClose.emit(0)
        this.reviewLoading = false
        this.modalService.dismissAll()
      },err => {
        this.reviewLoading = false
      })
    }else{
      this.reviewAPI.postFieldStatus(this.mainData).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          let response:string = res.responseObject
          if(response.length > 0){
            this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
          }else{
            this.toastr.success(INV_REVIEW_SUCCESS_MSG)
          }
          if(this.status == 2){
            this.modalClose.emit(0)
          }else{
            this.modalClose.emit(2)
          }
        }
        this.reviewLoading = false
        this.modalService.dismissAll()
      },err => {
        this.reviewLoading = false
      })
    }
  }

  unReviewForms(){
    this.unReviewLoading = true
    if(this.isFormLevel){
      this.reviewAPI.postFormStatus(this.mainData).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          let response:string = res.responseObject
          if(response.length > 0){
            this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
          }else{
            this.toastr.success(INV_REVIEW_SUCCESS_MSG)
          }
        }
        this.modalClose.emit(0)
        this.unReviewLoading = false
        this.modalService.dismissAll()
      },err => {
        this.unReviewLoading = false
      })
    }else{
      this.reviewAPI.postFieldStatus(this.mainData).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          let response:string = res.responseObject
          if(response.length > 0){
            this.toastr.warning(FORM_REVIEW_NOT_REVIEWED_MSG+""+res.responseObject)
          }else{
            this.toastr.success(INV_REVIEW_SUCCESS_MSG)
          }
          if(this.status == 2){
            this.modalClose.emit(0)
          }else{
            this.modalClose.emit(2)
          }
        }
        this.reviewLoading = false
        this.modalService.dismissAll()
      },err => {
        this.unReviewLoading = false
      })
    }
  }
  
  resetSignature(){
    this.isSignatureExist = false
    this.isUserReset = true
    this.unReviewLoading = false
    if(this.mainData.status == 0){
      this.isUnReviewDataExist = false
      this.isReviewDataExist = false
    }else{
      this.isUnReviewDataExist = false
      this.isReviewDataExist = false
    }
  }

  onFileSelect(event:any){
    if(event.files){
      if(event.files && event.files[0].size > 2097152){
        (<HTMLSpanElement>document.getElementById("fileSizeError")).innerHTML = SIGNATURE_FILE_SIZE_MSG
        this.canvasId = null
      }else{
        (<HTMLSpanElement>document.getElementById("fileSizeError")).innerHTML = ""
        this.canvasId = event.files[0]
      }
    }
  }
}