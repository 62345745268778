import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VERSION, baseURL } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class IndicationService {

  constructor(private http: HttpClient) { }

  getIndicationListData():Observable<any>{
    return this.http.get(`${baseURL}indications/${VERSION}/getList`)
  }

  getIndicationTableData():Observable<any>{
    return this.http.get(`${baseURL}indications/${VERSION}/list`)

  }

  getIndicationById(id:string):Observable<any>{
    return this.http.get(`${baseURL}indications/${VERSION}?id=${id}`);
  }
  
  saveIndication(payload:any):Observable<any>{
    return this.http.post(`${baseURL}indications/${VERSION}`,payload);
  }

  updateIndication(data:any):Observable<any>{
    return this.http.put<any>(`${baseURL}indications/${VERSION}`,data);
  }

  deleteIndication(id:string):Observable<any>{
    const params = new HttpParams({ fromObject: { id: id } })
    return this.http.delete(`${baseURL}indications/${VERSION}?id=${id}`);
  }
}
