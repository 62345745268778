import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VERSION, baseURL } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private http:HttpClient) { }

  getVisitFormImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/${VERSION}/design`,formData,{headers:headers});
  }
  getLogFormImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/${VERSION}/logform/design`,formData,{headers:headers});
  }
  getFormulaImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/${VERSION}/formula/design`,formData,{headers:headers});
  }
  getValidationImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/${VERSION}/validations`,formData,{headers:headers});
  }
  getDataImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/${VERSION}/data`,formData,{headers:headers});
  }
  getLogFormValidationImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/${VERSION}/validations/logForms`,formData,{headers:headers});
  }

  // getReviewImport(file:any,reviewType:any):Observable<any>{
  //   let formData = new FormData();
  //   let headers = new HttpHeaders();
  //   formData.append('file', file);
  //   headers.set("Content-Type","undefined")
  //   return this.http.post(`${baseURL}import/${VERSION}/submitForReview?reviewType=${reviewType}`,formData,{headers:headers});
  // }

  getUserImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/review/${VERSION}/users`,formData,{headers:headers});
  }

  getSdvImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/review/${VERSION}/sdv`,formData,{headers:headers});
  }
  
  getReviewImport(file:any,reviewType:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/review/${VERSION}/reviewType/${reviewType}`,formData,{headers:headers});
  }

  getQueryImport(file:any):Observable<any>{
    let formData = new FormData();
    let headers = new HttpHeaders();
    formData.append('file', file);
    headers.set("Content-Type","undefined")
    return this.http.post(`${baseURL}import/review/${VERSION}/query`,formData,{headers:headers});
  }
}
