import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PASSWORD_REQUIRED_MSG, USER_NAME_ERROR_MSG } from 'src/app/constant/responseMessage';
import { LoginService } from 'src/app/services/login.service';
import { StudyService } from 'src/app/services/study.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit{

  @Input() isStudyExportToProduction:any
  @Input() studyId:any
  
  loginCredForm!:FormGroup
  loginLoading:boolean = false

  userName:any

  constructor(private modalService:NgbModal, private toastr:ToastrService, private loginAPI:LoginService, private studyAPI:StudyService){
    this.loginCredForm = new FormGroup({
      username:new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required]),
      grant_type:new FormControl('password') 
    })
  }

  ngOnInit(): void {
    this.getStorageData()
  }

  getStorageData(){
    this.userName = sessionStorage.getItem("userName")
  }

  validateLoginCredentials(){
    let data = this.loginCredForm.value
    if(data.username == null || data.username == ''){
      this.toastr.warning(USER_NAME_ERROR_MSG)
      return false
    }else if(data.password == null || data.password == ''){
      this.toastr.warning(PASSWORD_REQUIRED_MSG)
      return false
    }
    return true
  }

  loginProcess(){
    var loginData = "username="+this.loginCredForm?.value.username+"&password="+this.loginCredForm?.value.password+"&grant_type=password"
    if(this.validateLoginCredentials()){
      this.loginLoading = true
      this.loginAPI.login(loginData).subscribe((res:any) => {
        if(res.access_token && this.userName == this.loginCredForm.value.username){
          if(this.isStudyExportToProduction){
            this.onStudyExportToProduction();
          }
        }else{
          this.toastr.warning("Login Credentials are not same")
        }
      })
    }
  }

  onStudyExportToProduction(){
    let jsonData:any = {}
    jsonData['studyId'] = this.studyId
    this.studyAPI.studyExport(jsonData).subscribe(res => {
      console.log(res);
      if(res.status == 0){
        this.modalService.dismissAll()
      }
    })
  }

  closeModal(){
    this.modalService.dismissAll()
  }
}
