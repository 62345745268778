import { Role } from "../models/Role";
import { QUERY_STATUS, QUERY_TYPE } from "./constant";
import { HttpHeaders } from '@angular/common/http';

export function getTokenData(key: string) {
    return sessionStorage.getItem(key);
}
export function cloneArray(json:any){
    let data = JSON.stringify(json)
    return JSON.parse(data);
}
export function getDateTime(file:any,role:any,studyName:any){
    let d = new Date()
    var year = d.getFullYear();
    var month = (new Date().getMonth() + 1);
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();
    var dateStyle = day + "/" + month + "/" + year + "_" + hours + ":" + minutes + ":" + seconds
    let fileName = null;
    // if(role == 6){
    //     fileName = file + "_" + dateStyle
    // }
    if(studyName == null){
        fileName = file + "_" + dateStyle;
    }
    else{
        fileName = file + "_" + studyName + "_" + dateStyle
    }
    return fileName
}
export function hideDataWithTypeAndStatusAndRole(type:any,status:any,roles:any){
    if(type == QUERY_TYPE[0].id){
        if(status == QUERY_STATUS[0].id || status == QUERY_STATUS[1].id){
            if(roles == Role.CRC || roles == Role.CRA || 
                // roles == Role.Monitor || 
                roles == Role.DM || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.CRA || 
                // roles == Role.Monitor ||
                roles == Role.DM || roles == Role.Investigator){
                return false
            }
        }
        return true
    }else if(type == QUERY_TYPE[1].id){
        if(status == QUERY_STATUS[0].id ){
            if(roles == Role.CRC || roles == Role.CRA || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.CRA || roles == Role.Investigator){
                return false
            }
        }
        return true
    }
    // else if(type == QUERY_TYPE[2].id){
    //     if(status == QUERY_STATUS[0].id ){
    //         if(roles == Role.CRC || 
    //             // roles == Role.Monitor || 
    //             roles == Role.Investigator){
    //             return false
    //         }
    //         return true
    //     }else{
    //         if(
    //             // roles == Role.Monitor ||
    //             roles == Role.Investigator){
    //             return false
    //         }
    //     }
    //     return true
    // }
    else if([QUERY_TYPE[2].id,QUERY_TYPE[3].id].includes(type)){
        if([QUERY_STATUS[0].id, QUERY_STATUS[1].id].includes(status) ){
            if(roles == Role.CRC || roles == Role.DM || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.DM || roles == Role.Investigator){
                return false
            }
        }
        return true
    }else if(type == QUERY_TYPE[4].id){
        if(status == QUERY_STATUS[0].id ){
            if(roles == Role.CRC || roles == Role.Investigator){
                return false
            }
            return true
        }else{
            if(roles == Role.Investigator){
                return false
            }
        }
        return true
    }
    return true
}

export function getTimeZoneOffset(): string {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Client Timezone:', clientTimezone);
    return clientTimezone;
}

export function getTimeZoneHeader(){
    return new HttpHeaders().set('Timezone',getTimeZoneOffset())
  }