<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">{{title}}</h3>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
	</div>
    <div class="modal-body">
        <div class="card text-black msgDesign mb-3" *ngIf="responseData.length > 0">
            <div class="card-body">
                <div class="row">
                    <div class="col-11">
                        <h5 class="card-title">Operation not performed for following subject</h5>
                    </div>
                    <div class="col-1 text-end">
                        <button class="control-buttons" (click)="responseData = []"><span class="btn-close"></span></button>
                    </div>
                </div>
                <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Subject Id</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of responseData">
                            <td>{{item.subjectId}}</td>
                            <td>{{item.status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
	    <form *ngIf="studyLockingForm" [formGroup]="studyLockingForm">
			<h5>Soft Lock By:</h5>
            <div class="form-check form-check-inline" *ngFor="let item of studyLockingType;let i = index">
                <input class="form-check-input" type="radio" formControlName="lockType" id="radioBtn" [value]="item.id" (change)="onLockTypeChange(item)">
                <label class="form-check-label" for="radioBtn">{{item.name}}</label>
            </div>
        </form>
        <br>
        <form *ngIf="studyLockingFilter" [formGroup]="studyLockingFilter">
            <div class="row" *ngIf="studyLockingForm.get('lockType')?.value != undefined">
                <div class="col" *ngIf="studyLockingForm.get('lockType')?.value == studyLockingType[1].id">
                    <!-- <div class="form-floating mb-3">
                        <select class="form-select" formControlName="siteId" id="siteId">
                            <option [ngValue]="null" selected>--Select--</option>
                            <option *ngFor="let site of siteData" [value]="site.id">{{ site.name }}</option>
                        </select>
                        <label for="siteId">Select Site</label>
                    </div> -->
                    <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Site" formControlName="siteIds"
                    [settings]="siteDropDownSettings" [data]="siteData">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col" *ngIf="studyLockingForm.get('lockType')?.value == studyLockingType[1].id">
                    <!-- <div class="form-floating mb-3">
                        <select class="form-select" formControlName="subjectId" id="subjectId">
                            <option [ngValue]="null" selected>--Select--</option>
                            <option *ngFor="let subject of subjectData" [value]="subject.id">{{ subject.subjectId }}</option>
                        </select>
                        <label for="subjectId">Select Subject</label>
                    </div> -->
                    <ng-multiselect-dropdown class="dropdown-list" placeholder="Subject" formControlName="subjectIds"
                    [settings]="subjectDropDownSettings" [data]="subjectData">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col" *ngIf="studyLockingForm.get('lockType')?.value == studyLockingType[0].id">
                    <div class="form-floating mb-2">
                        <ng-multiselect-dropdown class="dropdown-list" formControlName="visitIds" placeholder="Visit"
                            [settings]="visitDropdownSettings" [data]="visitsList"
                            (onDeSelect)="deSelectVisit()" (onDeSelectAll)="deSelectVisit()"
                            (onSelect)="onSelectVisit()" (onSelectAll)="onSelectVisit()">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-3">
                    <button class="btn btn-outline-primary" (click)="onStudyLocking(null,2)">
                        <b>Lock</b>
                    </button> &nbsp;
                    <button class="btn btn-outline-danger" (click)="onStudyLocking(null,0)">
                        <b>Unlock</b>
                    </button>
                </div>
            </div>
        </form>
        <!-- <div *ngIf="tableData.length && studyLockingForm.get('lockType')?.value == studyLockingType[0].id">
            <table class="table table-inverse table-responsive table-light">
                <thead class="thead-inverse table-light">
                    <tr>
                        <th><input type="checkbox" class="form-check-input"></th>
                        <th>Visit Name</th>
                        <th>
                            Status &nbsp; 
                            <button class="control-buttons" title="Lock Visit">
                                <span class="lockIcon" [style.color] = "errorIconColor"></span>
                            </button>
                            <button class="control-buttons" title="Unlock Visit">
                                <span class="unLockIcon" [style.color] = "successIconColor"></span>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tableData;let i = index">
                        <td><input type="checkbox" class="form-check-input"></td>
                        <td>{{item.name}}</td>
                        <td>
                            <button class="control-buttons" *ngIf="item.status != null && item.status == 1" (click)="onStudyLocking(item,true)" title="Lock Visit" >
                                <span class="lockIcon" [style.color] = "errorIconColor"></span>
                            </button>
                            <button class="control-buttons" *ngIf="item.status == null || item.status == 0" (click)="onStudyLocking(item,false)" title="Unlock Visit">
                                <span class="unLockIcon" [style.color] = "successIconColor"></span>
                            </button>
                            <button class="btn btn-outline-success" (click)="onStudyLocking(item,true)"><b>Lock</b></button>&nbsp;
                            <button class="btn btn-outline-danger" (click)="onStudyLocking(item,false)"><b>Unlock</b></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="closeModal()"><b>Close</b></button>
        <!-- <button [hidden]="isLocked == 1" type="button" class="btn btn-outline-success" (click)="addSubjectComment()">
            <b>
                Save <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
            </b>
        </button> -->
    </div>
</div>