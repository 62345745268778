import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IMPORT_DATA_POINTS, IMPORT_DATA_REVIEW_POINTS, IMPORT_TYPE } from 'src/app/constant/constant';
import { IMPORT_QUERIES_ERROR_MSG, IMPORT_QUERIES_MSG, IMPORT_QUERIES_PROMPT_ANSWER_MSG, IMPORT_QUERIES_PROMPT_MSG, IMPORT_TITLE } from 'src/app/constant/responseMessage';
import { ImportService } from 'src/app/services/import.service';

@Component({
  selector: 'app-import-popup',
  templateUrl: './import-popup.component.html',
  styleUrls: ['./import-popup.component.css']
})
export class ImportPopupComponent implements OnInit{
  
  title:string = "Study"
  
  @Input() rowData:any
  @Input() index:any

  importData:any = IMPORT_DATA_POINTS
  importReviewData:any = IMPORT_DATA_REVIEW_POINTS

  importStudyForm:FormGroup

  isLocked:any

  tabsData:any = IMPORT_TYPE
  activateTab:any = 0
  isTabValidation:boolean = true

  constructor(private modalService:NgbModal,private importAPI:ImportService,
    private toast:ToastrService){
    this.importStudyForm = new FormGroup({
      type:new FormControl(),
      file:new FormControl()
    })
  }
  
  ngOnInit(): void {
    this.getStorageData()
  }

  getStorageData(){
    localStorage.setItem("tempStudyId",this.rowData.id)
    this.isLocked = sessionStorage.getItem("isLocked")
  }

  onTabsChange(event:any){
    this.importStudyForm.reset()
    if(event.target.text == this.tabsData[0]){
      this.isTabValidation = true
    }else{
      this.isTabValidation = false
    }
  }
  onSetTab(index:number){
    this.activateTab = index
  }

  onFileSelect(event:any){
    console.log("EVENT :: ",event);
    if(event.files){
      console.log("HIT :: ",event.files);
      this.importStudyForm.get("file")?.setValue(event.files[0])
      
    }
  }

  //API's
  onImportVisitForms(){
    this.importAPI.getVisitFormImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }
  
  onImportLogForms(){
    this.importAPI.getLogFormImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }
  
  onImportFormula(){
    this.importAPI.getFormulaImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }

  onImportValidation(){
    this.importAPI.getValidationImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }
  
  onImportData(){
    this.importAPI.getDataImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }

  onImportLogFormValidations(){
    this.importAPI.getLogFormValidationImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }

  onImportReview(reviewType:any){
    this.importAPI.getReviewImport(this.importStudyForm.get("file")?.value,reviewType).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }

  onImportQueries(){
    let confirmationPopup = prompt(IMPORT_QUERIES_MSG)
    if( confirmationPopup === IMPORT_QUERIES_PROMPT_ANSWER_MSG ){
      this.importAPI.getQueryImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
        console.log("RES :: ",res);
        this.toast.success(res.message)
      },(error:any) => {
        this.toast.error(error)
      })
    }else if (confirmationPopup != IMPORT_QUERIES_PROMPT_ANSWER_MSG && confirmationPopup !== null){
      alert(IMPORT_QUERIES_PROMPT_MSG)
    }
  }

  onImportUsers(){
    this.importAPI.getUserImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }

  onImportSdv(){
    this.importAPI.getSdvImport(this.importStudyForm.get("file")?.value).subscribe((res:any) => {
      console.log("RES :: ",res);
      this.toast.success(res.message)
    },(error:any) => {
      this.toast.error(error)
    })
  }

  importStudyData(){
    let type = this.importStudyForm.get("type")?.value
    if(this.activateTab == 0){
      if(type == IMPORT_DATA_POINTS[0].id){
        this.onImportVisitForms()
      }else if(type == IMPORT_DATA_POINTS[1].id){
        this.onImportLogForms()
      }else if(type == IMPORT_DATA_POINTS[2].id){
        this.onImportFormula()
      }else if(type == IMPORT_DATA_POINTS[3].id){
        this.onImportValidation()
      }else if(type == IMPORT_DATA_POINTS[4].id){
        this.onImportLogFormValidations()
      }else if(type == IMPORT_DATA_POINTS[5].id){
        this.onImportData()
      }
    }else{
      if(type == IMPORT_DATA_REVIEW_POINTS[0].id){
        this.onImportUsers()
      }else if(type == IMPORT_DATA_REVIEW_POINTS[1].id){
        this.onImportReview(type)
      }else if(type == IMPORT_DATA_REVIEW_POINTS[2].id){
        this.onImportSdv()
      }else if(type == IMPORT_DATA_REVIEW_POINTS[3].id){
        this.onImportQueries()
      }
    }
    this.closeModal()
  }

  closeModal(){
    this.modalService.dismissAll()
    this.importStudyForm.reset()
  }
}
