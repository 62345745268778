<div class="container-fluid">
  <div class="jumbotron jumbotron-fluid">
    <div class="row">
      <div class="col-6">
        <p class="heading">Report Logs</p>
      </div>
      <div class="col-6 text-end mt-3" *ngIf="auditlogRolewise()">
      </div>
    </div>
  </div>
  <div class="row" *ngIf="roles == 6 && userName == 'admin'">
    <div class="col-3">
      <div class="form-floating mb-2">
        <select class="form-select" (change)="onStudyChange($event.target)">
          <option selected disabled>--Select--</option>
          <option *ngFor="let item of studyData" [value]="item.id" [selected]="item.id == studyIdData">{{item.name}}</option>
        </select>
        <label for="floatingSelect">Select Study</label>
      </div>
    </div>
  </div>
  <form [formGroup]="auditLogFilter">
    <div class="row">
      <div class="col">
        <input type="text" class="form-control custom-pages-input" placeholder="Message" formControlName="message">
      </div> 
      <div class="col">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list"
          formControlName="subjectIds"
          placeholder="Subject"
          [settings]="subjectDropdownSettings"
          [data]="subjectsList">
        </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col">
        <div class="form-floating mb-2">
          <ng-multiselect-dropdown class="dropdown-list"
          formControlName="userIds"
          placeholder="User"
          [settings]="userDropdownSettings"
          [data]="usersList">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-2">
        <div class="form-floating mb-2">
          <div class="input-group">
            <input class="form-control" rangeSeparator="To" [owlDateTime]="dt1" [selectMode]="'range'" 
            placeholder="Date & Time Range" [readOnly]="true" formControlName="currentDate" (dateTimeInput)="onSelectDate($event)">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [owlDateTimeTrigger]="dt1">
                <i class="fa fa-calendar"  aria-hidden="true"></i>
              </button>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="clearDateFilter()">
                <i class="fa fa-close"  aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <owl-date-time #dt1 pickerMode="dialog" [hour12Timer]="true"></owl-date-time>
      </div>
      <div class="col text-center">
        <div class="form-floating mb-2">
          <button type="button" class="btn btn-outline-primary" (click)="filterReportLog()">Search</button>
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-danger" (click)="clearFilters()"><b>Clear Filters</b></button>
      </div>
    </div>
  </form> 
    <hr>

    <!-- Data-Entry Table -->
    <div class="row">
      <div class="col-12" *ngIf="tableData.length; else noData">
        <table class="table table-responsive table-hover table-light">
          <thead class="thead-light">
            <tr>
              <th scope="col">Subject Id</th>
              <th scope="col">Message</th>
              <th scope="col">Added / Modified By</th>
              <th scope="col">Date & Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableData; let i = index">
              <td>{{ row.subjectAutoId }}</td>
              <td>{{ row.message }}</td>
              <td>{{ row.whoUpdatedName }}</td>
              <td>{{ row.createdDate | date : studyDateFormat }}</td>
            </tr>
          </tbody>
        </table>
        <div class="tableFooterHr"></div>
        <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true"
          [rotate]="true" [ellipses]="false" (pageChange)="onChangePagination()">
          <ng-template ngbPaginationPages let-page let-pages="pages">
            <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
              <div class="mb-3 d-flex flex-nowrap px-2">
                <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
                <input
                  #i
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="form-control custom-pages-input"
                  id="paginationInput"
                  [value]="page"
                  (keyup.enter)="selectPage(i.value, pages.length)"
                  (blur)="selectPage(i.value, pages.length)"
                  (input)="formatInput($any($event).target, pages.length)"
                  aria-labelledby="paginationInputLabel paginationDescription"
                  style="width: 2.5rem"
                  autocomplete="off"
                />
                <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
              </div>
            </li>
          </ng-template>
        </ngb-pagination>
      </div>
    </div>
    <ng-template #noData>
      <h5>No Data Available!</h5>
    </ng-template>
  
</div>
  