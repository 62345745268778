import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormulaServiceService } from 'src/app/services/formula-service.service';
import {  CONSTANT_PARAMETER_ERROR_MSG, DATE_FORMULA_ERROR_MSG, FORMULA_NAME_ERROR_MSG, PARAMETER_ERROR_MSG } from 'src/app/constant/responseMessage';
import { DEFAULT_UUID, DEFAULT_VISIT_NAME, VALIDATION_FORM_TYPE, componentTypeMap } from 'src/app/constant/constant';
import { FieldService } from 'src/app/services/field.service';
import * as loadashJson from 'lodash';
import { cloneArray } from 'src/app/constant/globalFunction';
import { FormService } from 'src/app/services/form.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-formula-popup',
  templateUrl: './formula-popup.component.html',
  styleUrls: ['./formula-popup.component.css']
})
export class FormulaPopupComponent  implements OnInit, OnDestroy {
  
  [x: string]: any;
  @Input() fieldId = ""
  @Input() editFormId = ""
  @Input() formulaId = ""
  @Input() dataTypeId: any;
  @Input() formType: any;
  @Input() multiRowId: any;
  @Input() multiRowDataTypeId: any;
  
  formValue: FormGroup
  formFormula: FormGroup
  
  itemData:any
  mainFormula:any
  existingFormula:any
  
  toggleData:boolean = true
  toggleForm:boolean = false
  
  operatorsSelect: any[] = ["+", "-", "x", "/","sqrt(","^","(",")"]

  visitSelect:any
  formSelect:any
  fieldsSelect:any[] = []
  data: any[] = []
  valueData:any[] = []

  formulaDetails:any[] = []

  saveLoading:boolean = false
  updateLoading:boolean = false

  saveUpdateFlag:boolean = true

  isEditFormula:boolean = true

  dateFunctions: any = [
    {
      name:"Add Days",
      equation: "addDays("
    },
    {
      name:"Add Months",
      equation: "addMonths("
    },
    {
      name:"Add Years",
      equation: "addYears("
    },
    {
      name:"Subtract Day",
      equation: "subtractDays("
    },
    {
      name:"Subtract Month",
      equation: "subtractMonth("
    },
    {
      name:"Subtract Year",
      equation: "subtractYears("
    },
    {
      name:"Todays Date",
      equation: "todaysDate()"
    }
  ]

  parameterData:any[] = [
    {name:"Constant",value:"constant"},
    {name:"Question",value:"question"}
  ]

  selectedVisit:any;
  formulaMainId:any

  isLocked:any
  roles:any

  formTypeData:any = VALIDATION_FORM_TYPE

  @Output() modalFormulaCount: EventEmitter<any> = new EventEmitter();
  @Output() modalFormulaClose: EventEmitter<any> = new EventEmitter();

  visitListMainData:any
  logVisitListMainData:any
  logVisitSelect:any

  columnSelect:any
  fieldDataTypeId:any

  isValid: boolean = true;
  public customFormula:any 
  
  private expandableDiv: HTMLElement | null = null;
  private resizeHandle: HTMLElement | null = null;
  private isResizing: boolean = false; // Flag to track if resizing is in progress

  constructor(private fieldAPI:FieldService,private formulaAPI:FormulaServiceService,
    private modalService: NgbModal,private toastr:ToastrService,private calender:NgbCalendar,
    private formAPI:FormService) {

    this.formValue = new FormGroup({
      fieldId: new FormControl(this.fieldId, []),
      multiRowId: new FormControl(this.multiRowId, []),
      formulaIds: new FormArray([]),
      formulas:new FormControl()
    })

    this.formFormula = new FormGroup({
      id:new FormControl(),
      formType:new FormControl(this.formType),
      fieldId: new FormControl(this.fieldId),
      multiRowId: new FormControl(this.multiRowId),
      name: new FormControl('',[Validators.required]),
      visitSelect: new FormControl(),
      formSelect: new FormControl(),
      fieldsSelect: new FormControl(),
      columnSelect: new FormControl(),
      firstDataTypeId: new FormControl(),
      equation:new FormControl('',[Validators.required]),
      displayEquation:new FormControl('',[Validators.required]),
      formulaDetails:new FormControl(),
      numericData:new FormControl('',[]),
      dateFunction: new FormControl(),
      parameterOneFormulaType: new FormControl(''),
      parameterTwoFormulaType: new FormControl(''),
      formulaSelect: new FormControl(),
      secondFormType: new FormControl(this.formType),
      secondFormSelect: new FormControl(),
      secondFieldsSelect: new FormControl(),
      secondColumnSelect: new FormControl(),
      secondFormulaSelect: new FormControl(),
      secondVisitSelect: new FormControl(),
      secondDataTypeId: new FormControl(),
      constant: new FormControl({}),
      secondConstant: new FormControl(),
      uiFormSelect1:new FormControl(),
      uiFieldSelect1:new FormControl(),
      uiFormSelect2:new FormControl(),
      uiFieldSelect2:new FormControl(),
      uiColumnSelect1:new FormControl(),
      uiColumnSelect2:new FormControl(),
      parameter1:new FormControl({}),
      parameter2:new FormControl({}),
    })
  }

  ngOnInit(): void {
    this.getStorageData()
    // this.getAllData()
    // this.getVisitFormData()
    // this.getFormulaData()
    this.getParallelApiCall()
    console.log(this.formTypeData);
    if(this.dataTypeId == componentTypeMap[16].id){
      this.dataTypeId = this.multiRowDataTypeId
    }
    this.formValue?.controls['formulas'].disable();
    this.formFormula?.controls['equation'].disable();
     //Added by Pooja
    this.formFormula.get('equation')?.valueChanges.subscribe(value => {
      this.isValid = this.isValidEquation(); // Check validity
      this.customFormula = this.updateCustomFormula();
      this.expandableDiv = document.getElementById('expandable');
      this.resizeHandle = document.querySelector('.resize-handle');

      this.setupEventListeners();
    });
    //Ends
  }
  updateCustomFormula() {
    return this.data
        .filter(item => item.trim() !== "") // Filter out empty strings
        .map(item => {
            // Define a regex for operators and brackets
            const isOperatorOrBracket = ["+", "-", "*", "/", "(", ")", "isDateDifference(", "sqrt(",'^'].includes(item);
            const additionalClass = isOperatorOrBracket ? " operator" : "";
            return `<span class="custom-card p-2 ms-2 rounded border${additionalClass}">${item}</span>`;
        })
        .join("");
        
  }
  setupEventListeners() {
    if (this.expandableDiv && this.resizeHandle) {
        this.expandableDiv.addEventListener('click', this.onDivClick.bind(this));
        this.expandableDiv.addEventListener('input', this.onDivInput.bind(this));
        this.resizeHandle.addEventListener('mousedown', this.initResize.bind(this));
    }
  }


  onDivClick() {
    if (this.expandableDiv && this.expandableDiv.scrollHeight > 30) {
        this.expandableDiv.style.height = 'auto'; // Allow height to expand
        const scrollHeight = this.expandableDiv.scrollHeight; // Get the scroll height
        this.expandableDiv.style.height = `${scrollHeight}px`; // Set height to scroll height
    }
  }

  onDivInput() {
      if (this.expandableDiv) {
          const minHeight = 30;
          if (this.expandableDiv.scrollHeight < minHeight) {
              this.expandableDiv.style.height = `${minHeight}px`;
          } else {
              this.expandableDiv.style.height = 'auto'; // Allow height to expand
              const scrollHeight = this.expandableDiv.scrollHeight; // Get the scroll height
              this.expandableDiv.style.height = `${scrollHeight}px`; // Set height to scroll height
          }
      }
  }

  initResize(e: MouseEvent) {
      e.preventDefault(); // Prevent text selection
      this.isResizing = true; // Set resizing flag to true
      window.addEventListener('mousemove', this.startResize.bind(this)); 
      window.addEventListener('mouseup', this.stopResize.bind(this)); 
  }

  startResize(e: MouseEvent) {
      if (this.isResizing && this.expandableDiv) {
          const newHeight = e.clientY - this.expandableDiv.getBoundingClientRect().top;
          this.expandableDiv.style.height = `${Math.max(newHeight, 30)}px`;
      }
  }

  stopResize() {
      this.isResizing = false; // Reset resizing flag
      window.removeEventListener('mousemove', this.startResize.bind(this));
      window.removeEventListener('mouseup', this.stopResize.bind(this));
  }

  ngOnDestroy(): void {
      // Cleanup event listeners
      if (this.resizeHandle) {
          this.resizeHandle.removeEventListener('mousedown', this.initResize.bind(this));
      }
      window.removeEventListener('mousemove', this.startResize.bind(this));
      window.removeEventListener('mouseup', this.stopResize.bind(this));
  }
  isValidEquation(): boolean {
    const operators = ['+', '-', '*', '/', '±', '^'];
    const stack: string[] = [];
    let tokens = this.data;
    const functions = ['sqrt'];
    // Check the first and last tokens
    if (operators.includes(tokens[0]) || operators.includes(tokens[tokens.length - 1])) {
        return false; // Cannot start or end with an operator
    }
    for (let i = 0; i < tokens.length; i++) {
      const current = tokens[i];
      let next = tokens[i + 1];

      // Handle parentheses
      if (current === '(') {
          stack.push(current);
          // Ensure the previous token is an operator, function name, or it is the start of the equation
          if (i > 0 && !operators.includes(tokens[i - 1]) && tokens[i - 1] !== '(') {
              return false; // Invalid placement
          }
      } else if (current === ')') {
          if (stack.length === 0) {
              return false; // Unmatched closing parenthesis
          }
          stack.pop();
          // Ensure the next token is an operator or it is the end of the equation
          if (next && !operators.includes(next) && next !== ')') {
              return false; // Invalid placement
          }
      }

      // Check for valid function usage
      if (typeof current === 'string' && current == 'sqrt(') {
          // After a function call, we can have numbers, variables, or more parentheses
          let isValidFunctionCall = false;

          while (next !== undefined && next !== ')') {
            // Check if next is a valid token
            if (typeof next === 'number' || (typeof next === 'string' && !operators.includes(next))) {
                isValidFunctionCall = true; // Valid token after the function call
                i++; // Move to the next token
            } else if (operators.includes(next)) {
                // Allow operators to be part of the expression
                i++; // Move to the next token
            } else {
                return false; // Invalid token after function call
            }
            next = tokens[i + 1]; // Update the next token
        }

          // Check if a closing parenthesis follows the valid tokens
          if (next === ')') {
              i++; // Skip the closing parenthesis
          } else {
              return false; // If there is no closing parenthesis
          }

          continue;
      }

      // Check if the current token is a number
      const isCurrentNumber = typeof current === 'number' || /^[\d]+$/.test(current.toString());

      // Check if the next token exists
      if (next !== undefined) {
          const isNextNumber = typeof next === 'number' || /^[\d]+$/.test(next.toString());
          const isNextOperator = operators.includes(next);

          // Check for two consecutive numbers
          if (isCurrentNumber && isNextNumber) {
              return false; // Two numbers in a row
          }

          // Check for two consecutive operators
          if (operators.includes(current) && isNextOperator) {
              return false; // Two operators in a row
          }
      }

      // Allow variable names
      if (typeof current === 'string' && !operators.includes(current) && !current.endsWith('(')) {
          continue; // It's a valid variable name
      }
  }

  // If there are unmatched opening parentheses
  if (stack.length !== 0) {
      return false; // Unmatched opening parentheses
  }

  return true; // The structure is valid
  }
 


  getStorageData(){
    this.isLocked = sessionStorage.getItem("isLocked")
    this.roles = sessionStorage.getItem("role")
  }

  getAllData() {
    this.fieldAPI.getFormDB().subscribe(res => {
      this.visitSelect = cloneArray(res);
      this.getFormulaData()
    })

  }

  getParallelApiCall(){
    forkJoin({
      visitData:this.formAPI.getVisitFormData(''),
      logData:this.formAPI.getLogFormAPIData()
    }).subscribe(res => {
      this.formAPI.setVisitFormData(res.visitData);
      this.visitListMainData = this.formType > 0 ? res.logData.responseObject : res.visitData.responseObject
      const visits = this.formType > 0 ? res.logData.responseObject : res.visitData.responseObject['visit']
      this.visitSelect = cloneArray(this.extractVisits(visits))
      this.logVisitListMainData = res.logData.responseObject
      const logData = res.logData.responseObject['visit']
      this.logVisitSelect = cloneArray(this.extractVisits(logData))
      this.getFormulaData()
    })
  }

  onFormTypeSelect(event:any){
    this.formType = event.value
    if(this.formType > VALIDATION_FORM_TYPE[0].id){
      this.visitListMainData = cloneArray(this.logVisitListMainData)
      const visits = this.visitListMainData['visit']
      this.formFormula.get("visitSelect")?.setValue(DEFAULT_UUID);
      this.visitSelect = cloneArray(this.extractVisits(visits))
      const forms = this.visitListMainData['visit'][DEFAULT_UUID].forms
      this.formSelect = this.extractForms(forms)
    }else{
      if(loadashJson.isEqual(this.formFormula.get("parameter1")?.value,{})){
        this.formFormula.get("uiFormSelect1")?.reset()
        this.formFormula.get("uiFieldSelect1")?.reset()
      }else if(loadashJson.isEqual(this.formFormula.get("parameter2")?.value,{})){
        this.formFormula.get("uiFormSelect2")?.reset()
        this.formFormula.get("uiFieldSelect2")?.reset()
      }
      this.formAPI.getVisitFormData('',false).subscribe((res:any) => {
        this.visitListMainData = res.responseObject
        const visits = res.responseObject['visit']
        this.visitSelect = cloneArray(this.extractVisits(visits))
      })
    }
  }

  // Extracting Visit Data for setting it to selectionBox
  extractVisits(visits: any): any[] {
    let visitData:any[] = []
    Object.keys(visits).map((key) => {
      visitData.push({ id: key, name: visits[key].name })
    })
    return visitData
  }

  //Extracting Forms Data for setting it to selectionBox
  extractForms(visits: any[]) {
    let formsArray:any[] = []
    Object.keys(visits).map((key:any) => {
      formsArray.push({formId: visits[key].formId, formName: visits[key].formName, formType:visits[key].formType})
    })
    return formsArray
  }
  
  triggerModal(Component: any) {
    this.modalService.open(Component, { ariaLabelledBy: 'modal-basic-title' }).result;
  }

  get getFormulaIds(){
    return (<FormArray>this.formValue?.get('formulaIds')).controls;
  }
  
  getSelectedKey(keyName:any){
    return this.formFormula.get(keyName)
  }

  onFunctionChange(event:any){
    this.addNewData(event.value,event.value,0,null)
    this.onFormTypeSelect({value:this.formType})
  }

  addNewData(displayData:string, equationValue:string,index:any,fieldData:any) {
    this.data.splice(index,1,displayData)
    this.valueData.splice(index,1,equationValue)
    if(fieldData != null){
      this.formulaDetails.push(fieldData)
    }
    this.joinData()
  }

  removeNewData(index:any) {
    if(this.data.length > 0 && this.valueData.length > 0){
      this.data.splice(index,1)
      this.valueData.splice(index,1)
      if(this.formulaDetails){
        for (let i = 0; i < this.formulaDetails.length; i++) {
          if(this.formulaDetails[i].parameter == index){
            this.formulaDetails.splice(index,1)
          }
        }
      }
    }
    this.joinData()
  }

  onConstantDateSelected(event:any){
    let constantDate = "'"+event['year'] + "-" + event['month'] + "-" + event['day'] + "',"
    this.addNewData(constantDate,constantDate,1,null)
  }

  onConstantNumberSelected(constantNumber:any){
    let constantValue = constantNumber.value+')'
    this.addNewData(constantValue,constantValue,2,null)
  }

  onParameterValueChange(parameterValue:any){
    this.removeNewData(1)
    this.getSelectedKey("constant")?.reset()
  }

  on2ndParameterValueChange(parameterValue:any){
    this.removeNewData(2)
    this.getSelectedKey("secondConstant")?.reset()
  }

  on1stFieldChange(event:any,formType:any){
    let fieldName = this.getSelectedKey("uiFieldSelect1")?.value.find((data:any)=> data.id == event.value).label
    let dataTypeId = this.getSelectedKey("uiFieldSelect1")?.value.find((data:any)=> data.id == event.value).dataTypeId
    this.getSelectedKey("firstDataTypeId")?.setValue(dataTypeId)
    if(dataTypeId != componentTypeMap[16].id){
      let test = this.formFormula.get("parameter1")?.value
      if (test['visitName'] === undefined && test['formName'] === undefined) {
        test['visitName'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("visitSelect")?.value)?.name
        test['visitId'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("visitSelect")?.value)?.id
        test['formName'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("formSelect")?.value)?.formName
        test['formId'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("formSelect")?.value)?.formId
        
      }
      test['fieldId'] = event.value
      test['fieldName'] = fieldName
      if(formType == this.parameterData[1].value){
        let display = test['visitName']+"."+test['formName']+"."+test['fieldName'] + ","
        let eq = "'Q{"+test['visitId']+"."+test['formId']+"."+test['fieldId'] + "}',"
        let formulaData = {
          visitId:test['visitId'],formId:test['formId'],fieldId:test['fieldId'],parameter:1
        }
        this.addNewData(display,eq,1,formulaData)
      }
    }else{
      this.getSelectedKey("uiColumnSelect1")?.setValue(this.getSelectedKey("uiFieldSelect1")?.value.find((data:any)=> data.id == event.value).columnData)
    }

  }

  on1stColumnChange(event:any,formType:any){
    let fieldName = this.getSelectedKey("uiFieldSelect1")?.value.find((data:any)=> data.id == this.getSelectedKey("fieldsSelect")?.value).label
    this.fieldDataTypeId = this.getSelectedKey("uiFieldSelect1")?.value.find((data:any)=> data.id == this.getSelectedKey("fieldsSelect")?.value).dataTypeId

    let test = this.formFormula.get("parameter1")?.value
    if (test['visitName'] === undefined && test['formName'] === undefined) {
      test['visitName'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("visitSelect")?.value)?.name
      test['visitId'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("visitSelect")?.value)?.id
      test['formName'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("formSelect")?.value)?.formName
      test['formId'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("formSelect")?.value)?.formId
      
    }
    let multiRowName = this.getSelectedKey("uiColumnSelect1")?.value.find((data:any)=> data.id == event.value).colHeading
    test['fieldId'] = this.getSelectedKey("fieldsSelect")?.value
    test['fieldName'] = fieldName
    test['multiRowId'] = event.value
    test['multiRowName'] = multiRowName
    if(formType == this.parameterData[1].value){
      let display = test['visitName']+"."+test['formName']+"."+test['fieldName'] + "." + test['multiRowName'] + ","
      let eq = "'Q{"+ test['visitId'] + "." + test['formId'] + "." + test['fieldId'] + "." + test['multiRowId'] + "}',"
      let formulaData = {
        visitId:test['visitId'],formId:test['formId'],fieldId:test['fieldId'],multiRowId:test['multiRowId'],parameter:1
      }
      this.addNewData(display,eq,1,formulaData)
    }
  }

  on2ndFieldChange(event:any,formType:any){
    let fieldName = this.getSelectedKey("uiFieldSelect2")?.value.find((data:any)=> data.id == event.value).label
    let dataTypeId = this.getSelectedKey("uiFieldSelect2")?.value.find((data:any)=> data.id == event.value).dataTypeId

    this.getSelectedKey("secondDataTypeId")?.setValue(dataTypeId)

    if(dataTypeId != componentTypeMap[16].id){
      let test = this.formFormula.get("parameter2")?.value
      if (test['visitName'] === undefined && test['formName'] === undefined) {
        test['visitName'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("secondVisitSelect")?.value).name
        test['visitId'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("secondVisitSelect")?.value).id
        test['formName'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("secondFormSelect")?.value)?.formName
        test['formId'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("secondFormSelect")?.value)?.formId
        
      }
      test['fieldId'] = event.value
      test['fieldName'] = fieldName
      if(formType == this.parameterData[1].value){
        let display = test['visitName']+"."+test['formName']+"."+test['fieldName'] + ")"
        let eq = "Q{"+test['visitId']+"."+test['formId']+"."+test['fieldId'] + "})"
        let formulaData = {
          visitId:test['visitId'],formId:test['formId'],fieldId:test['fieldId'],parameter:2
        }
        this.addNewData(display,eq,2,formulaData)
      }
      let backendEquation = this.valueData.join("")
    }else{
      this.getSelectedKey("uiColumnSelect2")?.setValue(this.getSelectedKey("uiFieldSelect2")?.value.find((data:any)=> data.id == event.value).columnData)
    }
  }

  on2ndColumnChange(event:any,formType:any){
    let fieldName = this.getSelectedKey("uiFieldSelect2")?.value.find((data:any)=> data.id == this.getSelectedKey("secondFieldsSelect")?.value).label
    this.fieldDataTypeId = this.getSelectedKey("uiFieldSelect2")?.value.find((data:any)=> data.id == this.getSelectedKey("secondFieldsSelect")?.value).dataTypeId

    let test = this.formFormula.get("parameter2")?.value
    if (test['visitName'] === undefined && test['formName'] === undefined) {
      test['visitName'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("secondVisitSelect")?.value)?.name
      test['visitId'] = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("secondVisitSelect")?.value)?.id
      test['formName'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("secondFormSelect")?.value)?.formName
      test['formId'] = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("secondFormSelect")?.value)?.formId
      
    }
    let multiRowName = this.getSelectedKey("uiColumnSelect2")?.value.find((data:any)=> data.id == event.value).colHeading
    test['fieldId'] = this.getSelectedKey("secondFieldsSelect")?.value
    test['fieldName'] = fieldName
    test['multiRowId'] = event.value
    test['multiRowName'] = multiRowName
    if(formType == this.parameterData[1].value){
      let display = test['visitName']+"."+test['formName']+"."+test['fieldName'] + "." + test['multiRowName'] + ")"
      let eq = "Q{"+test['visitId']+"."+test['formId']+"."+test['fieldId'] + "." + test['multiRowId'] + "})"
      let formulaData = {
        visitId:test['visitId'],formId:test['formId'],fieldId:test['fieldId'],multiRowId:test['multiRowId'],parameter:1
      }
      this.addNewData(display,eq,2,formulaData)
    }
  }

  addExistingFormula() {
    this.toggleData = false
    this.toggleForm = true
    const control = new FormControl(null,[Validators.required]);
    (<FormArray>this.formValue?.get('formulaIds')).push(control);
  }
  
  removeOption(i: any) {
    (<FormArray>this.formValue?.get('formulaIds')).removeAt(i);
    if((<FormArray>this.formValue?.get('formulaIds')).length == 0){
      this.toggleData = true;
      this.toggleForm = false
    }
  }
  
  createNewFormula(){
    this.toggleForm = false
  }
  
  OnSelectFormulaData(formulaData: any) {
    this.itemData = this.existingFormula.find((cntry: any) => cntry.id == formulaData.value);
    this.mainFormula = this.itemData.equation;
    this.formValue?.controls['formulas'].setValue(this.mainFormula);
  }
  
  
  OnVisitSelect(visitData: any) {
    const forms = this.visitListMainData['visit'][visitData.value.toString()].forms
    this.formSelect = this.extractForms(forms)
    // this.formSelect = this.visitSelect
    //   .find((cntry: any) => cntry.visitId == visitData.value).forms;
  }
  
  
  OnFirstParameterVisitSelect(visitData: any) {
    const forms = this.visitListMainData['visit'][visitData.value.toString()].forms
    let data = this.extractForms(forms)
    // let data = this.visitSelect.find((cntry: any) => cntry.visitId == visitData.value).forms;
    let visitName = this.visitSelect.find((cntry: any) => cntry.id == visitData.value).name;
    this.formFormula.get("parameter1")?.setValue({})
    let test = this.formFormula.get("parameter1")?.value
    test['visitId'] = visitData.value
    test['visitName'] = visitName
    this.formFormula.get("uiFormSelect1")?.setValue(data)
    
  }
  
  
  OnSecondParameterVisitSelect(visitData: any) {
    const forms = this.visitListMainData['visit'][visitData.value.toString()].forms
    let data = this.extractForms(forms)
    // let data = this.visitSelect.find((cntry: any) => cntry.visitId == visitData.value).forms;
    let visitName = this.visitSelect.find((cntry: any) => cntry.id == visitData.value).name;
    this.formFormula.get("parameter2")?.setValue({})
    let test = this.formFormula.get("parameter2")?.value
    test['visitId'] = visitData.value
    test['visitName'] = visitName
    this.formFormula.get("uiFormSelect2")?.setValue(data)
  }
  
  OnSelectForm(formData:any) {
    this.fieldAPI.getFieldsByForm(formData.value).subscribe(res => {
      this.fieldsSelect = res.responseObject
    });
    // this.fieldsSelect = this.formSelect
    // .find((cntry: any) => cntry.id == formData.value).fields;
  }
  
  OnFirstParameterSelectForm(formData:any) {
    this.fieldAPI.getFieldsByForm(formData.value).subscribe(res => {
      let data = cloneArray(res.responseObject);
      //data = data.filter((cntry: any) => cntry.dataTypeId == componentTypeMap[11].id);
      let formName = this.getSelectedKey("uiFormSelect1")?.value.find((cntry: any) => cntry.formId == formData.value).formName;
  
      let test = this.formFormula.get("parameter1")?.value
      test['formId'] = formData.value
      test['formName'] = formName
      test['fieldId'] = null
      test['fieldName'] = null
      this.formFormula.get("uiFieldSelect1")?.setValue(data)
    });
  }
  
  OnSecondParameterSelectForm(formData:any) {
    this.fieldAPI.getFieldsByForm(formData.value).subscribe(res => {
      let data = res.responseObject;
      //data = data.filter((cntry: any) => cntry.dataTypeId == componentTypeMap[2].id);
      let formName = this.getSelectedKey("uiFormSelect2")?.value.find((cntry: any) => cntry.formId == formData.value).formName;
      let test = this.formFormula.get("parameter2")?.value
      test['formId'] = formData.value
      test['formName'] = formName
      test['fieldId'] = null
      test['fieldName'] = null
      this.formFormula.get("uiFieldSelect2")?.setValue(data)
    });
  }
  
  addData(data:string, value:string,fieldDetailJson:any) {
    this.data.push(data);
    this.valueData.push(value)
    
    if(fieldDetailJson != null){
      this.formulaDetails.push(fieldDetailJson)

    }
    
    this.joinData();
  }

  isDateDifferenceEquation(value1:any,value2:any){
    return "isDateDifference('"+value1+"','"+value2+"')"
  }
  
  fieldChanged() {//getting selected field value
    if (this.dataTypeId !== componentTypeMap[11].id) {
      
      let visitName = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("visitSelect")?.value)?.name
      let formName = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("formSelect")?.value)?.formName
      let fieldName = this.fieldsSelect.find((data: any) => data.id == this.formFormula?.get('fieldsSelect')?.value)?.label;
      this.fieldDataTypeId = this.fieldsSelect.find((data: any) => data.id == this.formFormula?.get('fieldsSelect')?.value)?.dataTypeId
      
      if(visitName == undefined || visitName == null ){
        visitName = DEFAULT_VISIT_NAME
      }
      if(this.fieldDataTypeId != componentTypeMap[16].id){
        let visit = this.formFormula.get("visitSelect")?.value
        let form = this.formFormula.get("formSelect")?.value
        let field = this.formFormula.get("fieldsSelect")?.value
        
        const forms = this.visitListMainData['visit'][visit].forms
  
        let formulaData:any = {
          visitId:visit,
          formId:Number(form),
          fieldId:Number(field),
          uiFormSelect:this.extractForms(forms),
          uiFieldSelect:this.fieldsSelect
        }
          
        let addInFormula:any = visit+"."+form+"."+field
        
        let showFormula:any
        if(this.valueData){
          let lastIndexData:string = ""
          if(this.valueData.length > 0){
            lastIndexData = this.valueData[this.valueData.length-1]
            if(lastIndexData.match(/isDateDifference\(/i)){
              showFormula = visitName+"."+formName+"."+fieldName+","
              this.addData(showFormula,"'Q{"+addInFormula+"}',",formulaData);
    
            }else if(lastIndexData.endsWith(",")){
              showFormula = visitName+"."+formName+"."+fieldName+")"
              this.addData(showFormula,"'Q{"+addInFormula+"}')",formulaData);
    
            }else{
              showFormula = visitName+"."+formName+"."+fieldName
              this.addData(showFormula,"Q{"+addInFormula+"}",formulaData);
            }
          }else{
            showFormula = visitName+"."+formName+"."+fieldName
            this.addData(showFormula,"Q{"+addInFormula+"}",formulaData);
          }
        }
        
        this.formFormula?.controls['fieldsSelect'].setValue(null);
      }else{
        this.columnSelect = this.fieldsSelect.find((data: any) => data.id == this.formFormula?.get('fieldsSelect')?.value)?.columnData
      }
    }
    
  }

  columnFieldChanged(){
    if (this.dataTypeId !== componentTypeMap[11].id) {
      
      let visitName = this.visitSelect.find((data:any)=> data.id == this.formFormula.get("visitSelect")?.value)?.name
      let formName = this.formSelect.find((data:any)=> data.formId == this.formFormula.get("formSelect")?.value)?.formName
      let fieldName = this.fieldsSelect.find((data: any) => data.id == this.formFormula?.get('fieldsSelect')?.value)?.label;
      this.fieldDataTypeId = this.fieldsSelect.find((data: any) => data.id == this.formFormula?.get('fieldsSelect')?.value)?.dataTypeId
      if(visitName == undefined || visitName == null ){
        visitName = DEFAULT_VISIT_NAME
      }
      let visit = this.formFormula.get("visitSelect")?.value
      let form = this.formFormula.get("formSelect")?.value
      let field = this.formFormula.get("fieldsSelect")?.value
      let multiRow = this.formFormula.get("columnSelect")?.value
      
      const forms = this.visitListMainData['visit'][visit].forms

      
      if(this.fieldDataTypeId == componentTypeMap[16].id){
        let formulaData:any = {
          visitId:visit,
          formId:Number(form),
          fieldId:Number(field),
          multiRowId:Number(multiRow),
          uiFormSelect:this.extractForms(forms),
          uiFieldSelect:this.fieldsSelect
        }
          
        let addInFormula:any = visit+"."+form+"."+field+"."+multiRow
        
        let showFormula:any
        
        this.columnSelect = this.fieldsSelect.find((data: any) => data.id == this.formFormula?.get('fieldsSelect')?.value)?.columnData
        let multiRowName = this.columnSelect.find((data: any) => data.id == this.formFormula?.get('columnSelect')?.value)?.colHeading

        formulaData['columnSelect'] = this.columnSelect
        
        if(this.valueData){
          let lastIndexData:string = ""
          if(this.valueData.length > 0){
            lastIndexData = this.valueData[this.valueData.length-1]
            if(lastIndexData.match(/isDateDifference\(/i)){
              showFormula = visitName+"."+formName+"."+fieldName+"."+multiRowName+","
              this.addData(showFormula,"'Q{"+addInFormula+"}',",formulaData);
    
            }else if(lastIndexData.endsWith(",")){
              showFormula = visitName+"."+formName+"."+fieldName+"."+multiRowName+")"
              this.addData(showFormula,"'Q{"+addInFormula+"}')",formulaData);
    
            }else{
              showFormula = visitName+"."+formName+"."+fieldName+"."+multiRowName
              this.addData(showFormula,"Q{"+addInFormula+"}",formulaData);
            }
          }else{
            showFormula = visitName+"."+formName+"."+fieldName+"."+multiRowName
            this.addData(showFormula,"Q{"+addInFormula+"}",formulaData);
          }
        }
        
        this.formFormula?.controls['columnSelect'].setValue(null);
      }
    }
  }
 
  removeData() {
    let test:string = this.valueData[this.valueData.length - 1]
    if(test != null && test.includes("Q{")){
      this.formulaDetails.pop()
    }
    if(test && test.includes("isDateDifference")){
      this.formulaDetails.pop()
    }
    if(this.dataTypeId == componentTypeMap[11].id){
      this.formulaDetails.pop()
    }
    this.data.pop()
    this.valueData.pop()
    this.joinData();
    
  }
  
  joinData() {//for formula visibulity
    let displayEquationJoinString:any = this.data.join(" ")
    this.formFormula?.controls['equation'].setValue(displayEquationJoinString.replaceAll("_"," "));
  }

  
  onKeyPressed(value:any) {
    var input = <HTMLInputElement>document.getElementById(value);
    input?.addEventListener('input', function () {
      this.value = this.value.replace(/[^0-9 \,\.]/, '')
    })
  }

  getNumericData(){
    this.addData(this.formFormula.get("numericData")?.value,this.formFormula.get("numericData")?.value,null)
    this.formFormula.get("numericData")?.reset()
  }
  
  resetClose(){
    this.formFormula.get("numericData")?.reset()
  }
  
  formulaValidation() {
    if(this.formFormula.get("name")?.value == null || this.formFormula.get("name")?.value == ''){
      this.toastr.warning(FORMULA_NAME_ERROR_MSG)
      return false
    }else if(this.dataTypeId === componentTypeMap[11].id){
      if (this.formFormula.get("dateFunction")?.value == null || this.formFormula.get("dateFunction")?.value == '') {
        this.toastr.warning(DATE_FORMULA_ERROR_MSG)
        return false  
      }

      if ((this.formFormula.get("parameterOneFormulaType")?.value == null || this.formFormula.get("parameterOneFormulaType")?.value == '') ||
          (this.formFormula.get("parameterTwoFormulaType")?.value == '' || this.formFormula.get("parameterTwoFormulaType")?.value == null)
      ){
        this.toastr.warning(PARAMETER_ERROR_MSG)
        return false
      }
      if (this.formFormula.get("parameterOneFormulaType")?.value == this.parameterData[0].value) {
        if (loadashJson.isEqual(this.formFormula.get("constant")?.value, {})) {
        this.toastr.warning(CONSTANT_PARAMETER_ERROR_MSG)
          return false;
        }
      }
    }
    return true
  }

  getFormulaData(){
    this.formulaAPI.getFormulaByFieldId(this.fieldId,this.multiRowId).subscribe(res=>{
      console.log("RESPONSE :: ",res);
      if(res.status == 0){
        this.saveUpdateFlag = false
        this.isEditFormula = false

        let response:any = res.responseObject

        this.formFormula.controls['id'].setValue(response.id)
        this.formFormula.controls['name'].setValue(response.name)
        this.formFormula.controls['fieldId'].setValue(response.fieldId)
        this.formFormula.controls['multiRowId'].setValue(response.multiRowId)
        this.formFormula.controls['equation'].setValue(response.displayEquation)
        this.formFormula.controls['displayEquation'].setValue(response.displayEquation)

        let equation:string = response.equation
        let equationDisplay = equation.split(" ")
        
        this.valueData = equationDisplay

        this.formulaMainId = response.id
        
        let display:any = response.displayEquation
        for (let index = 0; index < response.formulaDetails.length; index++) {
          let jsonData:any = {}
          jsonData['visitId'] = response.formulaDetails[index].visitId
          jsonData['formId'] = response.formulaDetails[index].formId
          jsonData['fieldId'] = response.formulaDetails[index].fieldId
          jsonData['multiRowId'] = response.formulaDetails[index].multiRowId
          this.formulaDetails.push(jsonData)
          

          
          if(response.formulaDetails[index].visitId == DEFAULT_UUID){
            this.formFormula.get("formType")?.setValue(VALIDATION_FORM_TYPE[1].id)
            this.formFormula.get("secondFormType")?.setValue(VALIDATION_FORM_TYPE[1].id)
            this.formType = VALIDATION_FORM_TYPE[1].id
            this.formAPI.getLogFormAPIData().subscribe((resLog:any) => {
              this.visitListMainData = cloneArray(resLog.responseObject)
              const visits = this.visitListMainData['visit']
              this.visitSelect = cloneArray(this.extractVisits(visits))
              let visitName:any = this.visitSelect.find((data:any) => data.id == response.formulaDetails[index].visitId).name
              const forms = this.visitListMainData['visit'][DEFAULT_UUID].forms
              this.formFormula.get("uiFormSelect2")?.setValue(this.extractForms(forms))
              let formName:any = this.formFormula.get("uiFormSelect2")?.value.find((data:any) => data.formId == response.formulaDetails[index].formId).formName
              this.fieldAPI.getFieldsByForm(response.formulaDetails[index].formId).subscribe(res => {
                this.formFormula.get("uiFieldSelect2")?.setValue(cloneArray(res.responseObject))
                let fieldName:any = this.formFormula.get("uiFieldSelect2")?.value.find((data:any) => data.id == response.formulaDetails[index].fieldId).label
                this.fieldDataTypeId = this.formFormula.get("uiFieldSelect2")?.value.find((data:any) => data.id == response.formulaDetails[index].fieldId).dataTypeId
                let disSame:any = visitName+"."+formName+"."+fieldName
                
                visitName = visitName.replaceAll(" ","_")
                formName = formName.replaceAll(" ","_");
                fieldName = fieldName.replaceAll(" ","_")
                
                let dis:any = visitName+"."+formName+"."+fieldName
                
                if(this.fieldDataTypeId == componentTypeMap[16].id){
                  let columnData:any = this.formFormula.get("uiFieldSelect2")?.value.find((data:any) => data.id == response.formulaDetails[index].fieldId).columnData
                  let multiRowName:any = columnData.find((data:any) => data.id == response.formulaDetails[index].multiRowId).colHeading
                  disSame = disSame + "." + multiRowName
                  multiRowName = multiRowName.replaceAll(" ","_")
                  dis = dis + "." + multiRowName
                }
                
                display = display.replaceAll(disSame,dis)
                let splitDisplay = display.split(" ")
                
                this.data = splitDisplay
                ///Added by pooja
                this.customFormula = this.updateCustomFormula();
                this.setupEventListeners();
                ///Ends

              
              });

            })
          }else{
            this.formFormula.get("formType")?.setValue(VALIDATION_FORM_TYPE[0].id)
            this.formFormula.get("secondFormType")?.setValue(VALIDATION_FORM_TYPE[0].id)
            this.formType = VALIDATION_FORM_TYPE[0].id
            this.formAPI.getVisitFormData('').subscribe((resVisit:any) => {
              console.log("LOG :: ",resVisit);
              
              this.visitListMainData = resVisit.responseObject
              const visits = resVisit.responseObject['visit']
              this.visitSelect = cloneArray(this.extractVisits(visits))
              let visitName:any = this.visitSelect.find((data:any) => data.id == response.formulaDetails[index].visitId).name
              const forms = this.visitListMainData['visit'][response.formulaDetails[index].visitId].forms
              
              this.fieldAPI.getFieldsByForm(response.formulaDetails[index].formId).subscribe(res => {
                this.formFormula.get("uiFormSelect2")?.setValue(this.extractForms(forms))
                this.formFormula.get("uiFieldSelect2")?.setValue(cloneArray(res.responseObject))
                let formName:any = this.formFormula.get("uiFormSelect2")?.value.find((data:any) => data.formId == response.formulaDetails[index].formId).formName
                let fieldName:any = this.formFormula.get("uiFieldSelect2")?.value.find((data:any) => data.id == response.formulaDetails[index].fieldId).label
                
                this.fieldDataTypeId = this.formFormula.get("uiFieldSelect2")?.value.find((data:any) => data.id == response.formulaDetails[index].fieldId).dataTypeId
                let disSame:any = visitName+"."+formName+"."+fieldName
                
                visitName = visitName.replaceAll(" ","_")
                formName = formName.replaceAll(" ","_");
                fieldName = fieldName.replaceAll(" ","_")
                
                let dis:any = visitName+"."+formName+"."+fieldName
                
                if(this.fieldDataTypeId == componentTypeMap[16].id){
                  let columnData:any = this.formFormula.get("uiFieldSelect2")?.value.find((data:any) => data.id == response.formulaDetails[index].fieldId).columnData
                  let multiRowName:any = columnData.find((data:any) => data.id == response.formulaDetails[index].multiRowId).colHeading
                  disSame = disSame + "." + multiRowName
                  multiRowName = multiRowName.replaceAll(" ","_")
                  dis = dis + "." + multiRowName
                }
                display = display.replaceAll(disSame,dis)
                let splitDisplay = display.split(" ")
                
                this.data = splitDisplay
                ///Added by pooja
                this.customFormula = this.updateCustomFormula();
                this.setupEventListeners();
                ///Ends
              });
            })
            
          }

        }
        
      }else{
        this.saveUpdateFlag = true
      }
    })
    this.setDefaultData()
  }
  
  saveFormula() {
    if(this.formulaValidation()){
      this.formFormula.get("fieldId")?.setValue(this.fieldId)
      this.formFormula.get("multiRowId")?.setValue(this.multiRowId)
      let jsonData = this.formFormula.value
      delete jsonData.parameter1;
      delete jsonData.parameter2;
      delete jsonData.parameterOneFormulaType;
      delete jsonData.parameterTwoFormulaType;
      delete jsonData.secondConstant;
      delete jsonData.secondFieldsSelect;
      delete jsonData.secondFormSelect;
      delete jsonData.secondFormulaSelect;
      delete jsonData.secondVisitSelect;
      delete jsonData.uiFieldSelect1;
      delete jsonData.uiFieldSelect2;
      delete jsonData.uiFormSelect1;
      delete jsonData.uiFormSelect2;
      delete jsonData.uiColumnSelect1;
      delete jsonData.uiColumnSelect2;
      delete jsonData.constant;
      delete jsonData.visitSelect;
      delete jsonData.formSelect;
      delete jsonData.fieldsSelect;
      delete jsonData.formulaSelect;
      delete jsonData.dateFunction;
      delete jsonData.numericData

      if (this.dataTypeId === componentTypeMap[11].id) {
        jsonData.equation = this.valueData[0]+this.valueData[1]+this.valueData[2];
      }

      if(this.formulaDetails){
        let minus:any = "-"
        for (let i = 0; i < this.formulaDetails.length; i++) {
          let dataTypeId:any
          
          if (this.formFormula.get('dateFunction')?.value !== null) {
            if (this.formulaDetails[i].visitId) {
              jsonData.equation = this.valueData.join(" ");
            }else{
              this.formulaDetails.splice(i, 1);
            }
          }else{
            jsonData.equation = this.valueData.join(" ")
          }
        }
        this.formulaDetails.forEach((data:any,index:any) => {
          delete this.formulaDetails[index].uiFieldSelect
          delete this.formulaDetails[index].uiFormSelect
          delete this.formulaDetails[index].columnSelect
          delete this.formulaDetails[index].parameter
          if(this.formulaDetails[index].visitId != null && typeof(this.formulaDetails[index].visitId) === 'object'){
            this.formulaDetails[index].visitId = this.formulaDetails[index].visitId[0]
          }
        })
      }
      jsonData.formulaDetails = this.formulaDetails
      let displayEquationJoinString:any = this.data.join(" ")
      jsonData.displayEquation = displayEquationJoinString.replaceAll("_"," ")
      
      if(this.saveUpdateFlag){
        this.saveLoading = true
        this.formulaAPI.postFormula(jsonData).subscribe(res =>{
          if(res.status != 0){
            this.toastr.error(res.message)
          }else{
            this.toastr.success(res.message)
            this.formValue?.reset();
            this.formFormula?.reset();
            this.modalService.dismissAll("Dismiss");
            let sendData = {
              fieldId:res.fieldId,
              formulaCount:1
            }
            this.modalFormulaCount.emit(sendData)
            this.modalFormulaClose.emit(0)
          }
          this.saveLoading = false
        },err=>{
          this.toastr.error(err.message)
          this.saveLoading = false
        })
      }else{
        this.updateLoading = true
        this.formulaAPI.updateFormula(jsonData).subscribe(res =>{
          if(res.status != 0){
            this.toastr.error(res.message)
          }else{
            this.toastr.success(res.message)
            this.formValue?.reset();
            this.formFormula?.reset();
            this.modalService.dismissAll("Dismiss");
          }
          this.updateLoading = false
          this.modalFormulaClose.emit(0)
        },err=>{
          this.toastr.error(err.message)
          this.updateLoading = false
        })
      }

    }
  }
  
  closePopup() {
    this.modalService.dismissAll();
  }

  editFormula(){
    this.isEditFormula = true
  }

  resetFormulaField(){
    this.formFormula.get('equation')?.setValue(null);
    this.formFormula.get('displayEquation')?.setValue(null);
    this.formulaDetails = [];
    this.valueData = [];
    this.data = [];
  }

  setDefaultData(){
    this.getSelectedKey('formType')?.setValue(this.formType)
    let visit: any = localStorage.getItem('visitIds')
    let formId: any = localStorage.getItem('formId') == null ? localStorage.getItem('previewId') : localStorage.getItem('formId')
    if (visit !== null && formId !== null) {
      // visit select
      this.selectedVisit = this.formType == 0 ? JSON.parse(visit)[0] : DEFAULT_UUID
      
      const forms = this.visitListMainData['visit'][this.selectedVisit].forms
      this.formSelect = this.extractForms(forms)
      this.formFormula.get('visitSelect')?.setValue(this.selectedVisit)
      this.formFormula.get('secondVisitSelect')?.setValue(this.selectedVisit)

      // formSelect
      this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
        let newData = res.responseObject
        this.getSelectedKey('formSelect')?.setValue(formId)
        this.fieldsSelect = cloneArray(newData)
        this.getSelectedKey('secondFormType')?.setValue(this.formType)
        this.getSelectedKey('secondFormSelect')?.setValue(formId)
        this.getSelectedKey('uiFormSelect1')?.setValue(this.formSelect)
        this.getSelectedKey('uiFormSelect2')?.setValue(this.formSelect)
        if(newData.length > 0){
          let firstParamData: any[]=[];
          let secondParamData: any[]=[];
          newData.forEach((data: any) => {
            if(data.dataTypeId == 12){
              firstParamData.push(data)
            }else{
              secondParamData.push(data)
            }
          });
          this.getSelectedKey('uiFieldSelect1')?.setValue(firstParamData)
          this.getSelectedKey('uiFieldSelect2')?.setValue(secondParamData)
        }
      });
    }
    // this.getFormulaData()
  }

  deleteFormula(){
    let msg = confirm("Are you sure you want to delete this formula?")
    if(msg){
      this.formulaAPI.deleteFormula(this.formulaMainId).subscribe(res => {
        console.log("RESPONSE :: ",res);
        if(res.status == 0){
          this.toastr.success(res.message)
          this.modalService.dismissAll();
          let sendData = {
              fieldId:this.fieldId,
              formulaCount:0
            }
          this.modalFormulaCount.emit(sendData)
        }else{
          this.toastr.error(res.message)
          this.modalService.dismissAll();
        }
      },err => {
        this.toastr.error(err)
      })
    }else{
      // this.toastr.error("Cancel Operation")
    }
  }

  getTodayDate(){
    let date = this.calender.getToday()
    let constantDate = "'"+date['year'] + "-" + date['month'] + "-" + date['day'] + "',"
    this.addNewData(constantDate,constantDate,1,null)
    this.formFormula.get("constant")?.setValue(date)
  }
}
