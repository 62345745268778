<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">Add Comment</h3>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
	</div>
	<form *ngIf="subjectCommentForm" [formGroup]="subjectCommentForm">
		<div class="modal-body">
			<div class="form-floating mb-3">
				<input type="text" class="form-control" id="comment" formControlName="comment"
				placeholder="Enter Comment">
				<label for="comment">Enter Comment</label>
			</div>
			<br>
			<div class="modal-footer">
				<button type="button" id="close" class="btn btn-outline-danger"
				data-bs-dismiss="modal" (click)="closeModal()"><b>Close</b></button>
				<button *ngIf="isSaveOrUpdate" [hidden]="isLocked == 1" type="button" class="btn btn-outline-success" (click)="addSubjectComment()" [disabled]="loading">
					<b>
						Save <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
					</b>
				</button>
				<button *ngIf="!isSaveOrUpdate" [hidden]="isLocked == 1" type="button" class="btn btn-outline-secondary" (click)="addSubjectComment()" [disabled]="loading">
					<b>
						Update <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
					</b>
				</button>
				<button *ngIf="!isSaveOrUpdate" [hidden]="isLocked == 1" type="button" class="btn btn-outline-danger" (click)="resetSubjectComment()" [disabled]="resetLoading">
					<b>
						Reset <span class="spinner-border spinner-border-sm" *ngIf="resetLoading" role="status" aria-hidden="true"></span>
					</b>
				</button>
			</div>
		</div>
	</form>
</div>