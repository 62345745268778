import { baseURL , VERSION } from 'src/app/constant/constant';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  
  constructor(private http: HttpClient) { }

  postSite(data: any):Observable<any>{
    return this.http.post(`${baseURL}site/${VERSION}`, data);
  }

  getListSite(pageNo:any,pageSize:any,siteNames?:any[],codes?:any[],emailIds?:any[]):Observable<any>{
    let url:string = `${baseURL}site/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`
    
    if(siteNames && siteNames.length > 0){
      url = url.concat(`&siteNames=${siteNames}`)
    }
    if(codes && codes.length > 0){
      url = url.concat(`&codes=${codes}`)
    }
    if(emailIds && emailIds.length > 0){
      url = url.concat(`&emailIds=${emailIds}`)
    }
    return this.http.get(url);
    
  }


  getSiteIdName():Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}/siteNames`);
  }

  getUpdateSite(id:any):Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}?id=${id}`);
  }

  // getAdminSite():Observable<any>{
  //   return this.http.get(`${baseURL}site/${VERSION}/adminList`);
  // }

  updateSite(id:string,data: any):Observable<any>{
    return this.http.put(`${baseURL}site/${VERSION}`,data);
  }

  deleteSite(id: string):Observable<any>{
    const params = new HttpParams({ fromObject: { id: id } })
    return this.http.delete(`${baseURL}site/${VERSION}?id=${id}`);
  }

  getUserSiteData():Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}/study`);
  }

  getSiteFilterList(userId:any,studyId:any,roleId:any):Observable<any>{
    return this.http.get(`${baseURL}site/${VERSION}/siteFilterList?userId=${userId}&studyId=${studyId}&roleId=${roleId}`)
  }

  getAllSiteSubjectData():Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/site-subject`);
  }

  getAllSiteSubjectDataByStudyId(studyId:any):Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/studyId/${studyId}/site-subject`);
  }
}