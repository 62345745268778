import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { EMAIL_DOES_NOT_EXIST_MSG } from 'src/app/constant/responseMessage';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  formValue:FormGroup
  email:any;

  loading:boolean = false
  errorMsg: any;

  constructor(private api:UserService,private router:Router,private toastr:ToastrService) {
    this.formValue = new FormGroup({
      email:new FormControl('')
    })
  }

  ngOnInit(): void {
  }

  resetPassword(){
    this.email = this.formValue.controls['email'].value
    this.loading = true
    this.api.resetPassword(this.email).subscribe(res => {
      console.log("RESPONSE :: ",res)
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        this.router.navigate([''])
      }
      this.loading = false
    },err=>{
      this.toastr.error(err.message)
      this.loading = false
    })
  }

  resendPassword(){
    this.email = this.formValue.controls['email'].value
    this.loading = true
    this.api.resendPassword(this.email).subscribe(res => {
      console.log("RESPONSE :: ",res)
      if(res.status != 0){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        this.router.navigate([''])
      }
      this.loading = false
      },err=>{
        if(err.status === 401){
          this.toastr.error(EMAIL_DOES_NOT_EXIST_MSG);
          this.loading = false;
        }else{ 
          this.toastr.error(err.message);
          this.loading = false;
        }
      })
    }
    onCancelClicked(){
    this.router.navigate([''])
  }
}
