import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { CANCEL_MSG, DELETE_MSG } from 'src/app/constant/responseMessage';
import { IndicationService } from 'src/app/services/indication.service';
import { TherapeuticService } from 'src/app/services/therapeutic.service';

@Component({
  selector: 'app-therapeutic',
  templateUrl: './therapeutic.component.html',
  styleUrls: ['./therapeutic.component.css'],
})
export class TherapeuticComponent implements OnInit {
  title: string = 'THERAPEUTIC';
  therapeuticData: any[] = [];
  indicationsData: any[] = [];
  isEdit: boolean = false;
  indicationDropDownSetting: IDropdownSettings = {};
  therapeuticForm!: FormGroup;
  therapeuticId: any;
  loading: boolean = false;

  constructor(
    private therapeuticSvc: TherapeuticService,
    private indicationApiSvc: IndicationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.createTherapeuticForm();
  }

  ngOnInit(): void {
    this.multiSelect();
    this.getTherapeuticList();
    this.getIndicationList();
  }

  getTherapeuticList() {
    this.therapeuticSvc
      .getTherapeuticList()
      .subscribe((therapeuticList: any) => {
        if (therapeuticList && therapeuticList.responseObject) {
          this.therapeuticData = therapeuticList.responseObject;
        }
      });
  }

  getIndicationList() {
    this.indicationApiSvc
      .getIndicationListData()
      .subscribe((indicationList: any) => {
        if (indicationList.responseObject) {
          this.indicationsData = indicationList.responseObject.map(
            (data: any) => {
              return {
                id: data.id,
                name: data.name,
              };
            }
          );
        }
      });
  }

  setStatus() {
    this.isEdit = false;
  }

  addTherapeutic() {
    this.loading = true;
    if (this.therapeuticForm.valid) {
      let requestBody = {
        areas: '',
        indications: [],
      };
      let indicationArray = [];
      let indications = this.therapeuticForm.get('indications')?.value;
      if(indications){
        indicationArray = indications.map((indication: any) => {
          return indication.id;
        });
      }

      requestBody.areas = this.therapeuticForm.get('areas')?.value;
      requestBody.indications = indicationArray;

      this.therapeuticSvc.saveTherapeutic(requestBody).subscribe(
        (response: any) => {
          this.isEdit = false;
          this.loading = false;
          this.getTherapeuticList();
          this.closeModal();
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.message);
          this.loading = false;
        }
      );
    }
  }

  createTherapeuticForm() {
    this.therapeuticForm = this.formBuilder.group({
      areas: ['', [Validators.required]],
      indications: [''],
    });
  }

  // OnSelect(therapeuticsData: any) {
  //   this.indicationsData = []
  //   this.indicationsData = this.therapeuticData
  //   .find((cntry: any) => cntry.therapeuticId == therapeuticsData.value).indications;
  // }

  closeModal() {
    this.therapeuticForm.reset();
    let ref = document.getElementById('cancel');
    ref?.click();
  }

  updateTherapeutic() {
    this.loading = true;
    if (this.therapeuticForm.valid && this.therapeuticForm.value) {
      let requestBody = {
        areas: '',
        indications: [],
        id: '',
      };
      let indicationArray = [];
      let indications = this.therapeuticForm.get('indications')?.value;
      if(indications){
        indicationArray = indications.map((indication: any) => {
          return indication.id;
        });
      }

      requestBody.areas = this.therapeuticForm.get('areas')?.value;
      requestBody.indications = indicationArray;
      requestBody.id = this.therapeuticId;

      this.therapeuticSvc.updateTherapeutic(requestBody).subscribe(
        (response: any) => {
          this.isEdit = false;
          this.loading = false;
          this.getTherapeuticList();
          this.closeModal();
          this.toastr.success(response.message);
        },
        (error) => {
          this.isEdit = false;
          this.loading = false;
          this.closeModal();
          this.toastr.success(error.message);
        }
      );
    }
  }

  editTherapeutic(row: any, index: any) {
    this.isEdit = true;

    this.therapeuticId = row.value.therapeuticId;

    this.therapeuticSvc.getTherapeuticById(row.value.therapeuticId).subscribe(
      (res: any) => {
        console.log("RESPONSE :: ",res);
        if (res.status != 0) {
          this.toastr.error(res.message);
        } else {
          if (res.responseObject) {
            let therapeuticData = res.responseObject[0];
            this.therapeuticForm.get('areas')?.setValue(therapeuticData.areas);
            this.therapeuticForm
              .get('indications')
              ?.setValue(therapeuticData.indications);
          }

          // this.toastr.success(res.message);
          // if (this.therapeuticData.length) {
          //   this.therapeuticData.splice(index, 1);
          // }
        }
      },
      (err) => {
        this.toastr.error(err.message);
      }
    );
  }

  deleteTherapeutic(row: any, index: any) {
    let r = confirm(DELETE_MSG);
    if (r == true) {
      this.therapeuticSvc.deleteTherapeutic(row.value.therapeuticId).subscribe(
        (res: any) => {
          if (res.status != 0) {
            this.toastr.error(res.message);
          } else {
            this.toastr.success(res.message);
            if (this.therapeuticData.length) {
              this.therapeuticData.splice(index, 1);
            }
          }
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
    } else {
      this.toastr.error(CANCEL_MSG);
    }
  }

  multiSelect() {
    this.indicationDropDownSetting = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
  }

  deSelectIndications() {}
}
