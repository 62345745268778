<div class="modal-content">
    <form>
        <div class="modal-header">
            <h5 class="modal-title">Warning !!! </h5>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col">
                    <i class="fa fa-exclamation-triangle" [style.color]="partialSuccessIconColor"></i> {{message}}
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closePopup()">
                <b>Cancel</b>
            </button>

            <button type="submit" class="btn btn-outline-success" (click)="acceptWarning()">
                <b>Ok</b>
            </button>

          </div>
    </form>
</div>